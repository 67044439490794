import {functions} from "./firebase-config.js";
import {httpsCallable} from "firebase/functions";

const easyCallable = (functionsInstance, fn_name) => {
    const call = httpsCallable(functionsInstance, "performFunction")
    return args => call({...args, fn_name}).then(({data}) => data)
}

export const getNonceToSign = easyCallable(
    functions,
    'getNonceToSign'
);

/**
 * @type {function(VerifySignedMessageParams): Promise<{token: string}>}
 */
export const getSigninToken = easyCallable(
    functions,
    'verifySignedMessage'
);

export const getCurrentUserData = easyCallable(
    functions,
    'getCurrentUserData'
);

export const afterFirstLogin = easyCallable(
    functions,
    'afterFirstLogin'
);

/**
 *
 * @type {function(): Promise<{name: string, pool: string, balance: float}[]>}
 */
export const getMyTokens = easyCallable(
    functions,
    'getMyTokens'
);

/**
 *
 * @type {function({amount: number, slippageBps?: number}): Promise<SolToSeiQuote>}
 */
export const getSolToSeiQuote = easyCallable(
    functions,
    'getSolToSeiQuote'
);

/**
 *
 * @type {function({amount: number, slippageBps?: number}): Promise<SeiToSolQuote>}
 */
export const getSeiToSolQuote = easyCallable(
    functions,
    'getSeiToSolQuote'
);

export const executeTransaction = easyCallable(
    functions,
    'executeTransaction'
);

export const retryTransaction = easyCallable(
    functions,
    'retryTransaction'
);

/**
 * @param params {SolToSeiParameters}
 * @returns {Promise<any>}
 */
export const doSolanaDepositTransaction = (params) =>
    executeTransaction({type: "SolanaDepositTransaction", parameters: JSON.stringify(params)})

/**
 * @param params {SeiToSolParameters}
 * @returns {Promise<any>}
 */
export const doSolanaWithdrawTransaction = (params) =>
    executeTransaction({type: "SolanaWithdrawTransaction", parameters: JSON.stringify(params)})

/**
 * @param params {{amount: number}}
 * @returns {Promise<void>}
 */
export const doSeiDepositTransaction = (params) =>
    executeTransaction({type: "SeiDepositTransaction", parameters: JSON.stringify(params)})

/**
 * @param params {WithdrawParameters}
 * @returns {Promise<any>}
 */
export const doSeiWithdrawTransaction = (params) =>
    executeTransaction({type: "SeiWithdrawTransaction", parameters: JSON.stringify(params)})

/**
 * @param params {TradeParameters}
 * @returns {Promise<any>}
 */
export const doBuyTransaction = (params) =>
    executeTransaction({type: "BuyTransaction", parameters: JSON.stringify(params)})

/**
 * @param params {TradeParameters}
 * @returns {Promise<any>}
 */
export const doSellTransaction = (params) =>
    executeTransaction({type: "SellTransaction", parameters: JSON.stringify(params)})
