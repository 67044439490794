import React, {useEffect, useState} from 'react';
import {PoolChart} from "../components/token-page/PoolChart.jsx";
import {SelectionBar, SelectionOption} from "../components/token-page/SelectionBar.jsx";
import styled from "styled-components";
import {TransactionsTable} from "../components/token-page/TransactionsTable.jsx";
import {Header} from "../components/header/Header.jsx";
import {Page, SpacerXSmall} from "../globalStyles.js";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../firebase/firebase-config.js";
import {PoolInfo} from "../components/token-page/PoolInfo.jsx";
import {BuySell} from "../components/token-page/BuySell.jsx";
import {useTitle} from "../hooks/useTitle.js";

const Cols = styled.div`
    display: flex;
    flex-direction: row;
    width: ${({w}) => w || "100%"};
    height: ${({h}) => h || "100%"};
    flex-grow: ${({fg}) => fg || ""};
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: ${({fg}) => fg || 0};
`

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-basis: 1px;
    border-bottom: 1px solid #454545;
`

const Separator = styled.div`
    width: 1px;
    height: 100%;
    background-color: #454545;
`;

const ChartContainer = styled(Container)`
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
    border-radius: 8px;
    background-color: #161b26;
`

const StyledPoolChart = styled(PoolChart)`
    width: min(80vw, 100vw - 400px);
    height: 100%;
    padding-bottom: 8px;

    & #tv-attr-logo {
        z-index: 1;
    }
`

const TableCont = styled(Container)`
    width: min(80vw, 100vw - 400px);
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    border-color: #262730;
`

export function TokenPage({poolAddress}) {
    const [interval, setInterval] = useState(60 * 60 * 1000)
    const [type, setType] = useState("sei")
    const [pool, setPool] = useState(null)

    useTitle(pool ? pool?.stable_coin ? `SEI/${pool?.token_symbol}` : `${pool?.token_symbol}/SEI` : "Token")

    useEffect(() => onSnapshot(
        doc(db, "pools", poolAddress),
        snap => {
            setPool(snap.data())
        }), [poolAddress]);

    return <Page style={{fontSize: "12px"}}>
        <Header/>
        <SpacerXSmall/>
        <Container fg={1}>
            <Cols fg={1}>
                <ChartContainer>
                    <OptionsContainer>
                        <SelectionBar value={interval} setValue={setInterval}>
                            <SelectionOption value={60 * 1000}>1m</SelectionOption>
                            <SelectionOption value={5 * 60 * 1000}>5m</SelectionOption>
                            <SelectionOption value={15 * 60 * 1000}>15m</SelectionOption>
                            <SelectionOption value={60 * 60 * 1000}>1h</SelectionOption>
                            <SelectionOption value={3 * 60 * 60 * 1000}>3h</SelectionOption>
                            <SelectionOption value={6 * 60 * 60 * 1000}>6h</SelectionOption>
                            <SelectionOption value={24 * 60 * 60 * 1000}>1D</SelectionOption>
                            <SelectionOption value={7 * 24 * 60 * 60 * 1000}>7D</SelectionOption>
                        </SelectionBar>
                        <Separator/>
                        <SelectionBar value={type} setValue={setType}>
                            <SelectionOption value={"sei"}>SEI</SelectionOption>
                            <SelectionOption value={"usd"}>USD</SelectionOption>
                        </SelectionBar>
                    </OptionsContainer>
                    <StyledPoolChart pool={poolAddress} interval={interval} type={type}
                                     stable_coin={pool?.stable_coin}/>
                </ChartContainer>
                <SpacerXSmall/>
                <PoolInfo pool={pool}/>
            </Cols>
            <SpacerXSmall/>
            <Cols h={"fit-content"} w={"unset"}>
                <TableCont>
                    <TransactionsTable poolAddress={poolAddress} pool={pool}/>
                </TableCont>
                <SpacerXSmall/>
                <BuySell pool={pool}/>
            </Cols>
        </Container>
    </Page>
}