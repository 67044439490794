import {
    Page,
    SpacerGrow, SpacerLarge,
    SpacerMedium,
    SpacerXLarge,
    StyledButton,
    StyledButtonLink,
    TextSubTitle
} from "../globalStyles.js";
import styled from "styled-components";
import {useAuthStatus, useSetReferrer} from "../firebase/AuthProvider.jsx";
import {Row} from "../components/transfer/common.jsx";
import {Icon, IconBox} from "../components/header/Dialog.jsx";
import sei_logo from "../assets/sei_logo.webp";
import solana_logo from "../assets/sol_logo.webp";
import React, {useCallback, useEffect} from "react";
import {useWalletModal} from "@solana/wallet-adapter-react-ui";
import {useSelectWallet} from "@sei-js/react";
import {useTitle} from "../hooks/useTitle.js";

const CenteringDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 75vw;
    width: fit-content;
    margin: 0 auto;
    background: #1b1c25;
    border-radius: 32px;
    padding: 32px;
    border: 1px solid #262730FF;
`

const ConnectRow = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Button = styled(StyledButton)`
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export function Referral({referral_code}) {
    const authStatus = useAuthStatus()
    const {visible, setVisible} = useWalletModal();
    const {openModal} = useSelectWallet();
    const setReferrer = useSetReferrer()

    useTitle("Welcome to Slippage")

    useEffect(() => {
        setReferrer(referral_code)
        return () => setReferrer(null)
    }, [referral_code]);

    const handleSolanaClick = useCallback(
        (event) => {
            if (!event.defaultPrevented) setVisible(!visible);
        },
        [setVisible, visible]
    );

    return (
        <Page>
            <SpacerMedium/>
            <CenteringDiv>
                <SpacerGrow fg={2}/>
                {{"wallet-disconnected": <MessageContainer>
                        <TextSubTitle w={"bold"}>Sign up using a wallet with your referral code</TextSubTitle>
                        <TextSubTitle>Referral code: {referral_code}</TextSubTitle>
                        <SpacerXLarge/>
                        <Row>
                            <Button onClick={handleSolanaClick}>
                                <IconBox>
                                    <Icon src={solana_logo} alt={"solana"}/>
                                </IconBox>
                                <div>Solana</div>
                            </Button>
                            <Button onClick={() => openModal()}>
                                <IconBox>
                                    <Icon src={sei_logo} alt={"sei"}/>
                                </IconBox>
                                <div>Sei</div>
                            </Button>
                        </Row>
                    </MessageContainer>,
                    "signed-in": <MessageContainer>
                        <TextSubTitle w={"bold"}>Signed in with a preexisting account.</TextSubTitle>
                        <SpacerLarge/>
                        <StyledButtonLink to={"/"}>
                            <div>Main Page</div>
                        </StyledButtonLink>
                    </MessageContainer>,
                    "in-progress": <MessageContainer>
                        <SpacerLarge/>
                        <TextSubTitle w={"bold"}>Approve the signing of the login message using your wallet.</TextSubTitle>
                        <SpacerLarge/>
                    </MessageContainer>
                }[authStatus]}
                <SpacerGrow fg={3}/>
            </CenteringDiv>
        </Page>
    )
}