import user from "../../assets/user.svg"
import exchange from "../../assets/exchange.png"
import leaderboard from "../../assets/leaderboard.webp"
import profile from "../../assets/profile.webp"
import log_out from "../../assets/log_out.png"
import docs from "../../assets/docs.png"
import {SpacerAuto, UnstyledButton} from "../../globalStyles.js";
import React, {useCallback, useRef, useState} from "react";
import useSeiBalance from "../../hooks/useSeiBalance.js";
import {useUserData} from "../../firebase/AuthProvider.jsx";
import {useWallet} from "@solana/wallet-adapter-react";
import {Col, Container, Dialogue, DialogueRow, Icon, IconBox, Row, Text, useOnClickOutside} from "./Dialog.jsx";
import {useLocation} from "wouter";
import {useWallet as useSeiWallet} from "@sei-js/react";
import {getAuth, signOut} from "firebase/auth";
import {Amount} from "../Amount.jsx";


export function UserDetails() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const ref = useRef(null);
    const userData = useUserData()
    const seiBalance = useSeiBalance(userData?.wallets?.sei)
    const solanaWallet = useWallet();
    const seiWallet = useSeiWallet()
    const [, navigate] = useLocation();

    useOnClickOutside(ref, useCallback(() => setDialogOpen(false), []));

    const handleLogout = async () => {
        await solanaWallet.disconnect();
        seiWallet.disconnect()
        await signOut(getAuth())
        navigate("/")
    }

    return <Container ref={ref}>
        <Row onClick={() => setDialogOpen(true)}>
            <SpacerAuto/>
            <Col>
                <Text>
                    Balance
                </Text>
                <Text w={"bold"}>
                    <Amount num={seiBalance}/> SEI
                </Text>
            </Col>
            <Icon src={user} alt="user"/>
        </Row>
        <Dialogue z={2} visible={dialogOpen}>
            <DialogueRow onClick={() => navigate("/profile")}>
                <IconBox>
                    <Icon src={profile} alt={"profile"}/>
                </IconBox>
                <UnstyledButton>
                    Profile
                </UnstyledButton>
            </DialogueRow>
            <DialogueRow onClick={() => navigate("/transfer")}>
                <IconBox>
                    <Icon src={exchange} alt={"exchange"}/>
                </IconBox>
                <UnstyledButton>
                    Transfer Funds
                </UnstyledButton>
            </DialogueRow>
            <DialogueRow onClick={() => navigate("/leaderboard")}>
                <IconBox>
                    <Icon src={leaderboard} alt={"leaderboard"}/>
                </IconBox>
                <UnstyledButton>
                    Leaderboard
                </UnstyledButton>
            </DialogueRow>
            <DialogueRow onClick={() => window.open("https://slippage.gitbook.io/slippage", "_blank")}>
                <IconBox>
                    <Icon src={docs} alt={"Documentation"}/>
                </IconBox>
                <UnstyledButton>
                    Documentation
                </UnstyledButton>
            </DialogueRow>
            <DialogueRow onClick={handleLogout}>
                <IconBox>
                    <Icon src={log_out} alt={"log out"}/>
                </IconBox>
                <UnstyledButton>
                    Log Out
                </UnstyledButton>
            </DialogueRow>
        </Dialogue>
    </Container>
}