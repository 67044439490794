import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
    width: 100%;
    height: 5px;
    background-color: #ff4b92;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
`;

const GreenBar = styled.div`
    height: 100%;
    width: ${({percentage}) => percentage}%;
    background-color: #3fd7cc;
    transition: width 0.3s ease;
`;

export const Percentage = ({percentage}) => {
    return (
        <Container>
            <GreenBar percentage={percentage}/>
        </Container>
    );
};