import React, {useEffect, useState} from "react";
import {useSolToSeiQuote} from "../../hooks/useQuotes.js";
import {useUserData} from "../../firebase/AuthProvider.jsx";
import {
    BuySellColors,
    SpacerLarge,
    SpacerMedium,
    TextSubText,
    TextSubTitle,
    TransactionButton
} from "../../globalStyles.js";
import {Arrow, Col, LoadingIcon, Row, StyledTextField} from "./common.jsx";
import arrow_right from "../../assets/arrow-right.svg";
import {StyledCopyableText} from "../FancyText.jsx";
import black_spinner from "../../assets/black-spinner.svg";
import white_spinner from "../../assets/tube-spinner.svg";
import TransferConfirmDialog from "./TransferConfirmDialog.jsx";
import {Amount} from "../Amount.jsx";
import {useCryptoConnection} from "../../firebase/CryptoConnectionProvider.jsx";
import {useOngoingTransaction} from "../../hooks/useUserTransaction.js";

const txTypes = [
    "SolanaDepositTransaction",
    "SolanaWithdrawTransaction",
    "SeiDepositTransaction",
    "SeiWithdrawTransaction"
]

export function Deposit({seiWallet}) {
    const connection = useCryptoConnection()

    const [amount, setAmount] = useState("")
    const depositQuoteCont = useSolToSeiQuote(
        connection.protocol === "solana" && amount && parseFloat(amount),
        100
    )
    const [depositQuote, setDepositQuote] = useState(null)
    const [enabled, setEnabled] = useState(true)
    const [message, setMessage] = useState(null)
    const transaction = useOngoingTransaction(txTypes)
    const userData = useUserData()
    /**
     * @type{string}
     */
    const token = {solana: "sol", sei: "sei"}[connection.protocol] || ""
    const isTransactionDisabled = isNaN(parseFloat(amount)) || parseFloat(amount) === 0 || connection.protocol !== "sei" && (!enabled || !depositQuote || depositQuote.waiting)

    useEffect(() => {
        !message && enabled && setDepositQuote(depositQuoteCont)
    }, [message, depositQuoteCont, enabled]);

    useEffect(() => {
        setEnabled(!transaction || transaction.status !== "in-progress")
    }, [transaction]);

    const handleDeposit = async () => {
        try {
            setEnabled(false)
            let tid = await connection.depositFromWallet(parseFloat(amount), depositQuote, userData);
            if (!tid) {
                setEnabled(true)
            }
        } catch (e) {
            console.log(e)
            setEnabled(true)
        }
    };

    const showDialog = () => !isTransactionDisabled && setMessage({
        solana: <>
            Your {amount} SOL will be deposited as approximately <Amount num={depositQuote?.quote}/> SEI.
        </>,
        sei: <>
            Your {amount} SEI will be transferred to your Slippage wallet.
        </>,
    }[connection.protocol])

    return <>
        <TransferConfirmDialog
            message={message}
            showWarning={connection.protocol === "solana"}
            showFeeWarning={true}
            onCancel={() => setMessage(null)}
            onConfirm={() => {
                setMessage(null)
                handleDeposit().then()
            }}
        />
        <TextSubTitle>Deposit SEI from your {token.toUpperCase()} wallet</TextSubTitle>
        <SpacerMedium/>
        <Row w={"100%"}>
            <Col fb={0} fg={1}>
                <Col ml={"auto"}>
                    <TextSubText>From</TextSubText>
                    <StyledCopyableText length={12} text={connection.address}/>
                </Col>
            </Col>
            <Col>
                <TextSubText style={{visibility: "hidden"}}>a</TextSubText>
                <Arrow src={arrow_right} alt="arrow_right"/>
            </Col>
            <Col>
                <Col>
                    <TextSubText>Amount</TextSubText>
                    <StyledTextField
                        label={token + " amount"}
                        value={amount}
                        setValue={setAmount}
                    />
                </Col>
            </Col>
            <Col>
                <TextSubText style={{visibility: "hidden"}}>a</TextSubText>
                <Arrow src={arrow_right} alt="arrow_right"/>
            </Col>
            <Col fb={0} fg={1}>
                <Col mr={"auto"}>
                    <TextSubText>To</TextSubText>
                    <StyledCopyableText length={14} text={seiWallet}/>
                </Col>
            </Col>
        </Row>
        <SpacerLarge/>
        <TransactionButton
            p={"12px 24px"}
            {...BuySellColors.buy}
            disabled={isTransactionDisabled}
            onClick={showDialog}
        >
            <span>Deposit</span>
            {!enabled && <LoadingIcon src={black_spinner} alt="loading"/>}
        </TransactionButton>
        <SpacerMedium/>
        {connection.protocol === "solana" && <TextSubTitle h={"32px"}>{
            (depositQuote?.waiting && <LoadingIcon s={"1.5em"} src={white_spinner} alt="loading"/>)
            || (depositQuote?.quote && <><Amount num={depositQuote?.quote}/> SEI</>)
            || "..."
        }</TextSubTitle>}
    </>
}