import React from 'react';
import styled from 'styled-components';
import {Amount} from "./Amount.jsx";

const Container = styled.div`
    text-align: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Name = styled.div`
`;

const Value = styled.div`
    font-weight: bold;
`;

export const NameValue = ({name, value, valueStyle, prefix, integer = false, ...props}) => {
    return (
        <Container {...props}>
            {name && <Name>{name}</Name>}
            <Value style={valueStyle || {}}>
                {prefix}<Amount integer={integer} num={value}/>
            </Value>
        </Container>
    );
};

export const StyledNameValue = styled(NameValue)`
    padding: 5px;
    border: 1px solid gray;
    margin: 8px;
    border-radius: 8px;
`