import {Description, Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import styled from "styled-components";
import {SpacerGrow, SpacerLarge, SpacerMedium, SpacerSmall, SpacerXLarge} from "../globalStyles.js";

const CustomDialog = styled(Dialog)`
    position: relative;
    z-index: 50;
`

const DialogContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100vw; /* Full width of the viewport */
    height: 100vh;
    align-items: center; /* Vertically centers the content */
    justify-content: center; /* Horizontally centers the content */
    background-color: rgba(0, 0, 0, 0.5);
`

const CustomDialogPanel = styled(DialogPanel)`
    max-width: 32rem; /* Tailwind's max-w-lg corresponds to 32rem (512px) */
    padding: 3rem 4rem; /* 48px padding (Tailwind's p-12 is equivalent to 3rem) */
    background-color: #1b1c25; /* Sets the background color to white */
    color: white;
    text-align: center;
    border-radius: 32px;
    border: 1px solid #262730FF;
`
const ButtonContainer = styled.div`
    display: flex; /* Applies flexbox layout */
    justify-content: space-evenly;
    gap: 1rem; /* 16px space between flex items (Tailwind's gap-4 is equivalent to 1rem) */
`

const Button = styled.button`
    background-color: #bebab7;
    color: black;
`

export default function PrivateKeyFinalChanceDialog({isOpen, onCancel, onConfirm}) {
    return <CustomDialog open={isOpen} onClose={onCancel}>
        <DialogContainer>
            <SpacerGrow fg={1}/>
            <CustomDialogPanel>
                <DialogTitle style={{fontWeight: 'bold'}}>
                    FINAL CHANCE TO SAVE PRIVATE KEY
                </DialogTitle>
                <Description>Your private key will NOT be displayed again</Description>
                <SpacerXLarge/>
                <ButtonContainer>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={onConfirm}>I Saved It</Button>
                </ButtonContainer>
            </CustomDialogPanel>
            <SpacerGrow fg={1}/>
        </DialogContainer>
    </CustomDialog>
}