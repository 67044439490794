import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
    display: flex;
    vertical-align: middle;
    margin: auto 4px auto 0;
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
    display: block;
`;

const HiddenCheckbox = styled.input.attrs({type: "checkbox"})`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledCheckbox = styled.div`
    margin: auto;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    border: 2px solid white;
    transition: all 150ms;
    cursor: pointer;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px #b3d4fc;
    }

    ${Icon} {
        visibility: ${({checked}) => (checked ? "visible" : "hidden")};
    }
`;

export const Checkbox = ({checked, ...props}) => {
    return <CheckboxContainer  {...props}>
        <HiddenCheckbox checked={checked}/>
        <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24" width={12} height={12}>
                <polyline points="20 6 9 17 4 12"/>
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>;
};