import styled from 'styled-components';
import searchLogo from '../../assets/search.svg';
import {useEffect, useState} from "react";
import {TextInput} from "../TextInput.jsx";
import {UnstyledLink} from "../../globalStyles.js";
import spinner from "../../assets/tube-spinner.svg"
import {NameValue} from "../NameValue.jsx";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase/firebase-config.js";
import {nextChar} from "../../utils.js";
import {useCurrentSeiUsdPrice} from "../../firebase/CurrentSeiPriceProvider.jsx";

const SearchIcon = styled.img`
    margin-left: 4px;
    height: 14px;
    width: 14px;
`;

const LoadingIcon = styled.img`
    margin-left: 2px;
    margin-right: -2px;
    height: 18px;
    width: 18px;
`;

const Results = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, calc(100%));
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #1b1c25;
    border: 1px white solid;
    border-radius: 0 0 8px 8px;
    z-index: 10;
`

const Container = styled.div`
    margin: 2px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #fff;
    width: 400px;
    display: flex;
    justify-content: start;
    gap: 0.5em;
    align-items: center;
    position: relative;

    &:focus-within {
        border-radius: ${({out}) => out && "8px 8px 0 0"};

        ${Results} {
            display: flex;
        }
    }
`;

const ResultContainer = styled(UnstyledLink)`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    &:first-child {
        margin-top: 4px;
    }

    &:not(:last-child):after {
        content: "";
        border-bottom: 1px gray solid;
        margin: 4px auto;
        width: 90%;
    }

    &:last-child {
        margin-bottom: 4px;
    }
`

const Cols = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({jc}) => jc ? jc : "space-evenly"};
    align-items: center;
    font-size: 11px;
    margin-top: 4px;
`


const ResNameValue = styled(NameValue)`
    flex-direction: row;
    gap: 4px;
`

const StyledTextInput = styled(TextInput)`
    width: 100%;
    line-height: 1;
    color: gray;
`;

/**
 *
 * @param pool {Pool}
 * @param seiUsd
 * @returns {JSX.Element}
 * @constructor
 */
const Result = ({pool, seiUsd}) => {
    return <ResultContainer to={`/token/${pool.address}`}>
        <div>{pool.name}</div>
        <Cols>
            <ResNameValue value={pool.price_sei * seiUsd} prefix={"$"}/>
            <ResNameValue name={"24H VOLUME"} value={pool.volume[24 * 60 * 60 * 1000].total} prefix={"$"}/>
            <ResNameValue name={"MKT CAP"} value={pool.price_usd * pool.total_supply} prefix={"$"}/>
        </Cols>
    </ResultContainer>
}

const poolsRef = collection(db, "pools");

export const SearchBar = () => {
    const seiUsdPrice = useCurrentSeiUsdPrice()
    const [queryStr, setQueryStr] = useState("")
    const [results, setResults] = useState([])
    const [state, setState] = useState(0)

    useEffect(() => {
        if (queryStr === "") {
            setResults([])
            return
        }
        const queryStart = queryStr.toUpperCase()
        const queryEnd = queryStr.toUpperCase().replace(/.$/, nextChar);

        const my = Date.now()
        setState(my)

        //TODO migrate query to token_symbol_upper for case insensitive search
        const q = query(poolsRef,
            where("token_symbol", ">=", queryStart),
            where("token_symbol", "<", queryEnd),
            orderBy("volume.86400000", "desc"),
            limit(5)
        );

        getDocs(q)
            .then(snap => snap.docs)
            .then(newDocs => {
                setResults(newDocs)
                setState(curr => curr === my ? 0 : curr)
            });
    }, [queryStr])

    return (
        <Container out={results.length > 0}>
            {state !== 0 ?
                <LoadingIcon src={spinner} alt="loading"/> :
                <SearchIcon src={searchLogo} alt="looking-glass"/>}
            <StyledTextInput
                type="text"
                label="Search by token"
                value={queryStr}
                setValue={setQueryStr}
            />
            {results.length > 0 &&
                <Results>
                    {results.map(doc => (<Result seiUsd={seiUsdPrice} pool={doc.data()} key={doc.id}/>))}
                </Results>
            }
        </Container>
    );
};