import {getSeiToSolQuote, getSolToSeiQuote} from "../firebase/remoteFunctions.js";
import {useInterval} from "./useInterval.js";
import {delay} from "../utils.js";

/**
 *
 * @param amount {number}
 * @param inAddress {string}
 * @param outAddress {string}
 * @returns {DragonswapQuote | {waiting: true} | null}
 */
export const useDragonswapQuote = (amount, inAddress, outAddress) => useInterval(
    async () => {
        if (!amount || !inAddress || !outAddress) {
            return null
        }

        const dragonParams = new URLSearchParams({
            amount: amount.toLocaleString('fullwide', {useGrouping: false}),
            tokenInAddress: inAddress,
            tokenOutAddress: outAddress,
            type: "exactIn",
            protocols: "v2",
        });

        const [result] = await Promise.all([
                fetch(`https://sei-api.dragonswap.app/api/v1/quote?${dragonParams}`)
                    .then(response => response.json())
                    .catch(() => null),
                delay(750)
            ]
        )
        return result
    },
    5000,
    [amount, inAddress, outAddress],
    {waiting: true}
);

/**
 *
 * @param amount {number}
 * @param slippageBps {number?}
 * @returns {SolToSeiQuote | {waiting: true} | null}
 */
export const useSolToSeiQuote = (amount, slippageBps) => useInterval(
    async () => amount && getSolToSeiQuote({amount, slippageBps}),
    10000,
    [amount, slippageBps],
    {waiting: true}
)

/**
 *
 * @param amount {number}
 * @param slippageBps {number?}
 * @returns {SeiToSolQuote | {waiting: true} | null}
 */
export const useSeiToSolQuote = (amount, slippageBps) => useInterval(
    async () => amount && getSeiToSolQuote({amount, slippageBps}),
    10000,
    [amount, slippageBps],
    {waiting: true}
)