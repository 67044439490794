import {NameValue} from "./NameValue.jsx";
import React from "react";

export function PriceChange({name, pool, value, bold = true}) {
    if (!(pool && pool.price_sei_change)) {
        return <NameValue name={name} value={""}/>;
    }
    const change = pool.price_sei_change[value]
    return <NameValue
        name={name}
        value={change !== "-" ?
            (change * 100)
                .toFixed(2)
                .replace(/\.(\d*?)0+$/, ".$1")
                .replace(/\.$/, "") + "%" :
            "-"
        }
        valueStyle={{
            color:
                (Math.abs(change) < 0.00005 || change === "-") ? "gray" :
                    change > 0 ? "#3fd7cc" :
                        "#ff4b92",
            fontWeight: bold ? "bold" : "normal",
        }}
    />;
}