import {shortText} from "../../utils.js";
import {TableVirtuoso} from "react-virtuoso";
import styled from "styled-components";
import {useEffect, useState} from "react";
import change from "../../assets/change.png";
import {formatDistanceStrict} from 'date-fns';
import {enUSAbr} from 'date-fns/locale';
import useNow from "../../hooks/useNow.js";
import {Amount} from "../Amount.jsx";
import {getDatabase, limitToLast, onValue, orderByKey, query, ref} from "firebase/database";

const StyledTableVirtuoso = styled(TableVirtuoso)`
    height: unset !important;
    flex-grow: 1;
    width: 100%;
    border-radius: 8px;
    background-color: #1b1c25;

    & table {
        width: 100%;
    }
`

const StyledTd = styled.td`
    color: ${({c}) => c || ""};
    padding: 4px;
    font-size: 14px;
`

const StyledTh = styled.th`
    background-color: #1b1c25;
    padding: 6px;
    width: ${({w}) => w || ""};
`
const opposite = {buy: "sell", sell: "buy"}

/**
 *
 * @param poolAddress {string}
 * @param pool {Pool}
 * @returns {JSX.Element}
 * @constructor
 */
export function TransactionsTable({poolAddress, pool}) {
    const [docs, setDocs] = useState([])
    const [showAge, setShowAge] = useState(false)
    const [loadLimit, setLoadLimit] = useState(20)
    const now = useNow()

    useEffect(() => onValue(
        query(
            ref(getDatabase(), `transactions/${poolAddress}`),
            orderByKey(),
            limitToLast(loadLimit)
        ),
        snap => {
            setDocs(Object.values(snap.val()).sort((a, b) => b.timestamp - a.timestamp))
        }), [loadLimit])

    return <StyledTableVirtuoso
        data={docs}
        fixedHeaderContent={() => <tr>
            <StyledTh w={"20vw"}>Date <span><img src={change}
                                                 style={{height: "1em", verticalAlign: "middle"}}
                                                 alt={"change"}
                                                 onClick={() => setShowAge(!showAge)}/> </span>
            </StyledTh>
            <StyledTh w={"10vw"}>Type</StyledTh>
            <StyledTh w={"10vw"}>USD</StyledTh>
            <StyledTh w={"10vw"}>WSEI</StyledTh>
            <StyledTh w={"10vw"}>{pool?.token_symbol || ""}</StyledTh>
            <StyledTh w={"10vw"}>Txn</StyledTh>
        </tr>}
        itemContent={(index, row) => {
            const type = pool?.stable_coin ? opposite[row.type] : row.type
            return <>
                <StyledTd>{showAge ?
                    formatDistanceStrict(new Date(row.timestamp), now, {
                        addSuffix: false,
                        roundingMethod: "remainder",
                        locale: enUSAbr
                    }) :
                    new Date(row.timestamp).toLocaleString()
                }</StyledTd>
                <StyledTd c={type === "buy" ? "#3fd7cc" : "#ff4b92"}>{type.toUpperCase()}</StyledTd>
                <StyledTd>$<Amount num={row.value_usd}/></StyledTd>
                <StyledTd><Amount num={row.sei}/></StyledTd>
                <StyledTd><Amount num={row.token}/></StyledTd>
                <StyledTd>
                    <a href={`https://seitrace.com/tx/${row.txn}`} target={"_blank"}>
                        {shortText(row.txn, 9)}
                    </a>
                </StyledTd>
            </>;
        }}
        endReached={() => setLoadLimit(l => l + 20)}
        increaseViewportBy={400}
    />
}