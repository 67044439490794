import {useEffect, useState} from "react";
import {db} from "../firebase/firebase-config.js";
import {doc, onSnapshot} from "firebase/firestore";

/**
 *
 * @param tid {string | null | undefined}
 * @returns {TransactionRecord | undefined}
 */
export const useTransaction = (tid) => {
    const [transaction, setTransaction] = useState(null);

    useEffect(() => tid && onSnapshot(
        doc(db, "cloudTransactions", tid),
        snap => {
            setTransaction(snap.data())
        }) || undefined, [tid])

    return transaction
}