import {useMemo} from "react";
import {ethers} from "ethers"
import abi from "./erc20_abi.json";
import {useInterval} from "./useInterval.js";

const provider = new ethers.JsonRpcProvider("https://evm-rpc.sei-apis.com/");

/**
 *
 * @param token {string | undefined}
 * @param wallet {string | undefined}
 * @param decimals {number | undefined}
 * @returns {number | null | undefined}
 */
export default function useTokenBalance(token, wallet, decimals) {
    // noinspection JSValidateTypes
    /**
     * @type {ERC20Contract}
     */
    const erc20Contract = useMemo(() => token && new ethers.Contract(token, abi, provider), [token])
    return useInterval(() => erc20Contract && wallet && decimals && erc20Contract
        .balanceOf(wallet)
        .then(balance => parseFloat(balance.toString()) * 10 ** -decimals)
        .catch(console.error), 5000, [erc20Contract, wallet, decimals])
}