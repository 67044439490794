import {useInterval} from "./useInterval.js";
import {getQueryClient} from "@sei-js/cosmjs";

/**
 *
 * @param wallet {string}
 * @returns {number | null | undefined}
 */
export default (wallet) => useInterval(() =>
        wallet && getQueryClient("https://rest.sei-apis.com")
            .then(cli => cli.cosmos.bank.v1beta1.allBalances({address: wallet}))
            .then(({balances}) => balances
                .filter(balance => balance.denom === "usei")
                .map(balance => parseFloat(balance.amount) / 1e6)[0] || 0)
            .then(n => Math.max(n - 0.2, 0))
            .catch(console.error),
    5000, [wallet])