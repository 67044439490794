import {NameValue} from "../NameValue.jsx";
import React, {useState} from "react";
import styled from "styled-components";
import {SelectionBar, SelectionOption} from "./SelectionBar.jsx";
import {Percentage} from "./Percentage.jsx";
import telegram from "../../assets/telegram.png"
import website from "../../assets/website.webp"
import twitter from "../../assets/twitter.png"
import {PriceChange} from "../PriceChange.jsx";
import {useCurrentSeiUsdPrice} from "../../firebase/CurrentSeiPriceProvider.jsx";
import {CopyButton} from "../FancyText.jsx";

const TextTitle = styled.p`
    font-size: 24px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0 8px;
`;

const Cols = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
`

const IconCols = styled(Cols)`
    justify-content: space-evenly;
`

const StatTextCols = styled(Cols)`
    justify-content: space-between;
    padding: 0;

    & div:first-child div {
        text-align: left;
    }

    & div:last-child div {
        text-align: right;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px 24px;
    background-color: #1b1c25;
    flex-grow: ${({fg}) => fg || ""};
    justify-content: space-evenly;
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
`

const StyledOption = styled(SelectionOption)`
    text-align: center;
    flex-grow: 1;
    margin: 0 !important;

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`

const TotalNameValue = styled(NameValue)`
    text-align: left;
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: auto;
`

const PercentContainer = styled.div`
    display: flex;
    flex-basis: 0;
    flex-grow: 3;
    flex-direction: column;
`

const LinkIcon = styled.img`
    height: 32px;
    width: 32px;
    display: flex;

    &:hover {
        cursor: pointer;
    }
`;

const buySellRatio = ({buy, sell}) => (buy + sell) !== 0 ? 100 * buy / (buy + sell) : 50

/**
 *
 * @param pool {Pool}
 * @returns {Element}
 * @constructor
 */
export function PoolInfo({pool}) {
    const seiUsdPrice = useCurrentSeiUsdPrice();
    const [statisticInterval, setStatisticInterval] = useState(24 * 60 * 60 * 1000)
    const Interval = ({label, value, ...props}) =>
        <StyledOption value={value} {...props}>
            {pool?.price_sei_change && <PriceChange name={label} pool={pool} value={value}/>}
        </StyledOption>

    return <Container fg={1}>
        <TextTitle>
            {pool?.stable_coin ? `SEI/${pool?.token_symbol}` : `${pool?.token_symbol}/SEI`}
            <CopyButton s="1rem" text={pool?.token_addr || ""}/>
        </TextTitle>
        {(pool?.website || pool?.twitter || pool?.telegram) && <IconCols>
            {pool?.website && <a target={"_blank"} href={pool?.website}><LinkIcon src={website}/></a>}
            {pool?.twitter && <a target={"_blank"} href={pool?.twitter}><LinkIcon src={twitter}/></a>}
            {pool?.telegram && <a target={"_blank"} href={pool?.telegram}><LinkIcon src={telegram}/></a>}
        </IconCols>}
        <Cols>
            <NameValue name={"PRICE USD"} prefix={"$"}
                       value={pool?.stable_coin ? 1 / pool.price_sei : (pool?.price_sei * seiUsdPrice)}/>
            <NameValue name={"LIQUIDITY"} prefix={"$"} value={pool?.liquidity * seiUsdPrice}/>
            <NameValue name={"MKT CAP"} prefix={"$"} value={(pool && (pool.market_cap * seiUsdPrice))}/>
        </Cols>
        <SelectionBar p={"0"} value={statisticInterval} setValue={setStatisticInterval}>
            <Interval label={"5m"} value={5 * 60 * 1000}/>
            <Interval label={"1h"} value={60 * 60 * 1000}/>
            <Interval label={"6h"} value={6 * 60 * 60 * 1000}/>
            <Interval label={"24h"} value={24 * 60 * 60 * 1000}/>
        </SelectionBar>
        <Cols>
            <TotalNameValue integer={true} name={"TXNS"}
                            value={pool?.num_txns && pool.num_txns[statisticInterval].total}/>
            <PercentContainer>
                <StatTextCols>
                    <NameValue integer={true} name={"BUY"}
                               value={pool?.num_txns && pool.num_txns[statisticInterval].buy}/>
                    <NameValue integer={true} name={"SELL"}
                               value={pool?.num_txns && pool.num_txns[statisticInterval].sell}/>
                </StatTextCols>
                <Percentage
                    percentage={pool?.num_txns && buySellRatio(pool.num_txns[statisticInterval])}/>
            </PercentContainer>
        </Cols>
        <Cols>
            <TotalNameValue prefix={"$"}
                            name={"VOLUME"}
                            value={pool?.volume && pool.volume [statisticInterval].total}/>
            <PercentContainer>
                <StatTextCols>
                    <NameValue name={"BUY"}
                               prefix={"$"}
                               value={pool?.volume && pool.volume [statisticInterval].buy}/>
                    <NameValue name={"SELL"}
                               prefix={"$"}
                               value={pool?.volume && pool.volume [statisticInterval].sell}/>
                </StatTextCols>
                <Percentage
                    percentage={pool?.volume && buySellRatio(pool.volume [statisticInterval])}/>
            </PercentContainer>
        </Cols>
        <Cols>
            <TotalNameValue integer={true} name={"MAKERS"}
                            value={pool?.num_makers && pool.num_makers[statisticInterval].total}/>
            <PercentContainer>
                <StatTextCols>
                    <NameValue integer={true} name={"BUYERS"}
                               value={pool?.num_makers && pool.num_makers[statisticInterval].buy}/>
                    <NameValue integer={true} name={"SELLERS"}
                               value={pool?.num_makers && pool.num_makers[statisticInterval].sell}/>
                </StatTextCols>
                <Percentage
                    percentage={pool?.num_makers && buySellRatio(pool.num_makers[statisticInterval])}/>
            </PercentContainer>
        </Cols>
    </Container>
}