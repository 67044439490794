import styled from "styled-components";
import {Link} from "wouter";

// Used for wrapping a page component
export const Screen = styled.div`
    background-color: ${({bg}) => (bg ? bg : "#3F3F3F")};
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
    height: 8px;
    width: 8px;
    flex-basis: 8px;
    flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
    height: 16px;
    width: 16px;
    flex-basis: 16px;
    flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
    height: 24px;
    width: 24px;
    flex-basis: 24px;
    flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
    height: 32px;
    width: 32px;
    flex-basis: 32px;
    flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerXLarge = styled.div`
    height: 48px;
    width: 48px;
    flex-basis: 48px;
    flex-shrink: 0;
`;

// Used for providing space between components
export const SpacerXXLarge = styled.div`
    height: 64px;
    width: 64px;
    flex-basis: 64px;
    flex-shrink: 0;
`;

export const SpacerAuto = styled.div`
    height: 1px;
    width: 1px;
    margin: auto;
`;

export const SpacerGrow = styled.div`
    height: 1px;
    width: 1px;
    flex-grow: ${({fg}) => fg};
`;

export const Bold = styled.span`
    font-weight: bold;
`

// Used for providing a wrapper around a component
export const Container = styled.div`
    display: flex;
    flex: ${({flex}) => (flex ? flex : 0)};
    flex-direction: ${({fd}) => (fd ? fd : "column")};
    justify-content: ${({jc}) => (jc ? jc : "flex-start")};
    align-items: ${({ai}) => (ai ? ai : "flex-start")};
    background-color: ${({test}) => (test ? "pink" : "none")};
    width: 100%;
    background-image: ${({image}) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
`;

export const Grid = styled.div`
    display: grid;
    justify-content: ${({jc}) => (jc ? jc : "flex-start")};
    align-items: ${({ai}) => (ai ? ai : "flex-start")};
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
`

export const TextTitle = styled.p`
    color: var(--primary-text);
    font-size: ${({fs}) => fs || "36px"};
    font-weight: 500;
    line-height: 1.6;
    margin: 8px;
`;

export const TextSubTitle = styled.p`
    font-size: 20px;
    line-height: 1.6;
    font-weight: ${({w}) => w || "normal"};
    margin: 8px;
    height: ${({h}) => h};
    text-align: ${({ta}) => ta};
`;

export const TextDescription = styled.p`
    color: ${({color}) => color || "var(--primary-text)"};
    font-size: 16px;
    text-align: ${({ta}) => (ta ? ta : "justify")};
`;

export const TextSubText = styled.p`
    color: ${({color}) => color || "var(--primary-text)"};
    font-size: 12px;
    text-align: ${({ta}) => (ta ? ta : "justify")};
    font-weight: ${({w}) => w || "normal"};
`;

export const StyledClickable = styled.div`
    :active {
        opacity: 0.6;
    }
`;

// Used for providing a wrapper around a component
export const ImgOverlayContainer = styled.div`
    display: flex;
    flex: ${({flex}) => (flex ? flex : 0)};
    flex-direction: ${({fd}) => (fd ? fd : "column")};
    justify-content: ${({jc}) => (jc ? jc : "flex-start")};
    align-items: ${({ai}) => (ai ? ai : "flex-start")};
    background-color: ${({test}) => (test ? "pink" : "none")};
    background-image: ${({image}) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
    transform: rotate(${({rt}) => (rt ? rt : 0)});
    position: absolute;
    top: 0;
    left: 0;
    padding: ${({pd}) => (pd ? pd : 0)};
    width: 100%;
    height: 100%;
`;

export const FooterImg = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
`

export const StyledButton = styled.button`
    padding: 12px 16px;
    border-radius: 16px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    background-color: ${({disabled, bg}) => !disabled && bg || "#bebab7"};
    color: #000;
    width: fit-content;
    margin: 0 auto;
    cursor: ${({disabled}) => disabled ? "default" : "pointer"};

    &:hover {
        background-color: ${({hoverBg, disabled}) => !disabled && hoverBg};
    }
`;

export const StyledButtonLink = styled(Link)`
    padding: 12px 16px;
    border-radius: 16px;
    font-size: 20px;
    border: none;
    font-weight: bold;
    background-color: #bebab7;
    color: #000;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
`;

export const TransactionButton = styled(StyledButton)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: ${({w}) => w};
    padding: ${({p}) => p};
    line-height: 1;
`

export const StyledRoundedButton = styled.button`
    padding: 10px;
    border-radius: 50px;
    border: none;
    font-weight: bold;
    background-color: var(--accent);
    color: var(--accent-text);
    width: ${({width}) => width || "100px"};
    cursor: pointer;
    box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0 6px 0px -2px rgba(250, 250, 250, 0.3);

    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`;

export const StyledRoundButton = styled.button`
    padding: 10px;
    border-radius: 100%;
    border: none;
    background-color: var(--accent);
    color: var(--accent-text);
    font-weight: bold;
    font-size: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);
    -webkit-box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);
    -moz-box-shadow: 0 4px 0px -2px rgba(250, 250, 250, 0.3);

    :active {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
`;

export const ResponsiveWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    @media (min-width: 767px) {
        flex-direction: row;
    }
`;

export const StyledLogo = styled.img`
    width: 200px;
    @media (min-width: 767px) {
        width: 300px;
    }
    transition: width 0.5s, height 0.5s;
`;

export const StyledRoundLogo = styled.img`
    width: 200px;
    @media (min-width: 767px) {
        width: 300px;
    }
    transition: width 0.5s, height 0.5s;
    border-radius: 100%;
`;

export const StyledRoundImg = styled.img`
    border-radius: 100%;
    width: 200px;
    @media (min-width: 900px) {
        width: 250px;
    }
    @media (min-width: 1000px) {
        width: 300px;
    }
    transition: width 0.5s;
`;

export const StyledHalfRoundImg = styled.img`
    border-radius: 5%;
    width: ${({width}) => width || "100%"};
    transition: width 0.5s;
`;

export const StyledRectangleImg = styled.img`
    width: ${({width}) => width || "100%"};
    transition: width 0.5s;
`;

export const UnstyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    line-height: initial;
    align-content: center;

    &:hover {
        color: inherit;
    }
`;

export const UnstyledA = styled.a`
    text-decoration: none;
    color: inherit;
    line-height: initial;
    align-content: center;

    &:hover {
        color: inherit;
    }
`;



export const Logo = styled.a`
    display: flex;
    width: ${({width}) => width || "50px"};
    height: 50px;
    background-image: url(${({img}) => img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    cursor: pointer;

    &:active {
        background-image: url(${({click}) => click});
    }
`
export const UnstyledButton = styled.button`
    border: none;
    background: none;
    color: white;
    padding: 0;
    align-content: start;

    &:focus {
        outline: none;
    }
`
export const BuySellColors = {
    buy: {bg: "#6fd3cb", hoverBg: "#b1e7e3"},
    sell: {bg: "#ff4b92", hoverBg: "#ff99c2"},
    disabled: {bg: "#bebab7", hoverBg: "#bebab7"}
}

export const Page = styled.div`
    width: calc(100vw - 16px);
    min-height: calc(100vh - 16px);
    display: flex;
    flex-direction: column;
`