export function DefaultDict(lambda) {
    return new Proxy({}, {
        get(target, key, descriptor) {
            if (key in target || typeof key !== "string") {
                return Reflect.get(target, key, descriptor);
            } else {
                target[key] = lambda(key);
                return target[key]
            }
        }
    })
}

/**
 *
 * @param {TimeData} old
 * @param {TimeData} neu
 * @returns {TimeData}
 */
export function mergeTimeData(old, neu) {
    if (!old) return neu
    return {
        open: old.open,
        close: neu.close,
        high: Math.max(old.high, neu.high),
        low: Math.min(old.low, neu.low),
    }
}

/**
 *
 * @param input {string | number}
 * @param interval {number}
 * @returns {number}
 */
export function roundDown(input, interval) {
    return Math.floor((typeof input === "string" ? parseInt(input) : input) / interval) * interval;
}

export function shortText(text, length) {
    if (length < 0 || text.length <= length) {
        return text
    } else {
        const startLen = Math.ceil((length - 3) / 2);
        const endLen = Math.floor((length - 3) / 2);
        return text.slice(0, startLen) + "..." + text.slice(text.length - endLen)
    }
}

export const nextChar = c => String.fromCharCode(c.charCodeAt(0) + 1)

export function* range(from = 0, to, step = 1) {
    for (let i = from; to === undefined || i < to; i += step) {
        yield i
    }
}

export const delay = ms => new Promise((resolve) => setTimeout(resolve, ms));

export function retry(retries, fn, exponential = true, wait = 1000, hideErr=false) {
    return async function (...args) {
        let ret = "NOT_RUN_YET";
        let tries = 0;
        let currWait = wait;

        while (retries < 0 || retries - tries > 0) {
            try {
                ret = await fn(...args, tries);
                return ret;
            } catch (e) {
                tries++;
                !hideErr && console.error("Try ", tries);

                if (retries < 0 || retries - tries > 0) {
                    !hideErr && console.trace();
                    !hideErr && console.error(e, "Waiting for ms: ", currWait);
                } else {
                    throw e;
                }

                await delay(currWait);

                if (exponential) {
                    currWait = Math.floor(currWait * (2 + 2 * Math.random())); // Somewhere between double and quadruple the next wait time
                }
            }
        }

        throw new Error("Unreachable statement reached.");
    };
}