import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
    position: relative;
    font-size: ${({fontSize}) => fontSize || "16px"};
`;

// noinspection CssInvalidPseudoSelector
const InputField = styled.input`
    font-size: inherit;
    width: 100%;
    border: none;
    z-index: 1;
    background: none;
    color: white;

    &:focus {
        outline: none;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
        opacity: 0;
    }

    &:-internal-autofill-selected {
        background-color: transparent !important;
    }
`;

const InputLabel = styled.label`
    font-size: inherit;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    align-content: center;
    pointer-events: none;
`;

export const TextInput = ({label, value, setValue, ...props}) => {
    return <InputContainer {...props}>
        <InputField
            type="text"
            id="textInput"
            placeholder=" "
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoComplete="false"
            required
        />
        <InputLabel htmlFor="textInput">{label}</InputLabel>
    </InputContainer>;
};
