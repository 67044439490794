import styled from "styled-components";
import {TextInput} from "../TextInput.jsx";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 0;
    gap: ${({g}) => g};
    width: ${({w}) => w};
    flex-basis: ${({fb}) => fb};
    flex-grow: ${({fg}) => fg};
    height: ${({h}) => h};
`
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: ${({fb}) => fb};
    flex-grow: ${({fg}) => fg};
    margin: ${({m}) => m};
    margin-left: ${({ml}) => ml};
    margin-right: ${({mr}) => mr};
    gap: ${({g}) => g};
`
export const Arrow = styled.img`
    height: 36px;
    width: auto;
    margin: auto 12px;
`

export const LoadingIcon = styled.img`
    height: ${({s}) => s ?? "1em"};
    width: ${({s}) => s ?? "1em"};
    margin: ${({m}) => m ?? "auto 0 auto 0.25em"};
    //transform: translateY(10%);
`;

const TextFieldBorder = styled.div`
    font-size: 16px;
    padding: 8px 12px;
    border: #bebab7 1px solid;
    border-radius: 16px;
    width: 120px;
    background-color: #2f2f3e;
`

export const StyledTextField = props => <TextFieldBorder><TextInput {...props}/></TextFieldBorder>