import copy from "../assets/copy.svg"
import styled from "styled-components";
import {shortText} from "../utils.js";
import {TextInput} from "./TextInput.jsx";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: ${({m}) => m};
`

const FancyTextContainer = styled(Container)`
    padding: 8px 12px;
    border: #bebab7 1px solid;
    border-radius: 16px;
    background-color: #2f2f3e;
`

export const StyledCopyableText = styled(CopyableText)`
    padding: 8px 12px;
    border: #bebab7 1px solid;
    border-radius: 16px;
    background-color: #2f2f3e;
    //font-size: 12px;
`

const Centered = styled.div`
    height: fit-content;
    width: fit-content;
    margin: auto;
`

const Img = styled.img`
    margin-left: 8px;
    height: ${({s}) => s || "24px"};
    width: ${({s}) => s || "24px"};

    &:hover {
        cursor: pointer;
    }
`

export function CopyButton({text, ...props}) {
    return <Img src={copy} alt={"Copy Text"} onClick={() => navigator.clipboard.writeText(text)} {...props}/>
}

/**
 *
 * @param text {string}
 * @param length {number}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function CopyableText({text, length, ...props}) {
    let shown;
    shown = shortText(text, length);
    return <Container {...props}>
        <Centered>
            {shown}
        </Centered>
        <CopyButton text={text}/>
    </Container>;
}

export function FancyTextInput({label, value, setValue, fontSize, postfix, ...props}) {
    return <FancyTextContainer {...props}>
        <TextInput style={{flexGrow: "1"}} label={label} value={value} setValue={setValue} fontSize={fontSize}/>
        <div>{postfix}</div>
    </FancyTextContainer>;
}