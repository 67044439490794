import {createContext, useContext} from "react";
import {useSingleInterval} from "../hooks/useInterval.js";
import {retry} from "../utils.js";

/**
 * Retrieve sei/usd price data from pyth
 * The api returns all price updates within the minute with a rate of 30 requests / 10 seconds
 * @param t timestamp in seconds
 */
async function getSeiUsdPrices(t) {
    const time = Math.floor(parseInt(t) / 1000) - 20
    const result = await fetch(`https://benchmarks.pyth.network/v1/updates/price/${time}?ids=53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb&encoding=hex&parsed=true`)
        .then(response => response.json())
    const {
        parsed: [{
            price: {price, expo}
        }]
    } = result;
    return parseFloat(price) * 10 ** expo
}

/**
 * @type {React.Context<number>}
 */
const CurrentSeiPriceContext = createContext(0)

export const useCurrentSeiUsdPrice = () => useContext(CurrentSeiPriceContext);

const retrySeiPrice = retry(-1, (tries) => getSeiUsdPrices(Date.now() - 10000 * tries), false, 500, true)

export function CurrentSeiPriceProvider({children}) {
    const seiUsdPrice = useSingleInterval(() => retrySeiPrice(), 5000, [])
    return <CurrentSeiPriceContext.Provider value={seiUsdPrice}>
        {children}
    </CurrentSeiPriceContext.Provider>
}
