import {TableVirtuoso} from "react-virtuoso";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {collection, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../firebase/firebase-config.js";
import {useLocation} from "wouter";
import {Page, SpacerXSmall} from "../globalStyles.js";
import {Header} from "../components/header/Header.jsx";
import {PriceChange} from "../components/PriceChange.jsx";
import {Amount} from "../components/Amount.jsx";
import {useTitle} from "../hooks/useTitle.js";
import {useCurrentSeiUsdPrice} from "../firebase/CurrentSeiPriceProvider.jsx";
import question_mark from "../assets/question_mark.png"

const TableWrapper = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
    border-radius: 8px;
    background-color: #1c1c25;
    flex-grow: 1;
    padding-top: 8px;
    display: flex;
`

const StyledTableVirtuoso = styled(TableVirtuoso)`
    width: 100%;
    height: unset !important;
    padding: 0 8px;
    & table {
        width: 100%;
    }

    & thead {
        z-index: 1 !important;
    }
`

const StyledTd = styled.td`
    color: ${({c}) => c || ""};
    padding: 4px 8px;
    font-size: 14px;
    text-align: left;
`

const Tr = styled.tr`
    &:hover {
        cursor: pointer;
        background-color: #2f2f3e;
    }
`

const StyledTh = styled.th`
    background-color: #1b1c25;
    padding: 6px 8px;
    text-align: left;
    width: ${({w}) => w || ""};

    &:hover {
        cursor: ${({clickable}) => clickable && "pointer"};
    }
`

const TokenPicture = styled.object`
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    transform: translateY(0.25rem);
    margin-right: 0.25rem;
    border-radius: 50%;
    background-image: url(${question_mark});
    background-size: cover;
    background-repeat: no-repeat;
`

const TokenImg = styled.img`
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
`

const poolsRef = collection(db, "pools");

const NmAmount = ({num, ...props}) => <Amount num={num == -1 ? "-" : num} {...props}/>

const TokenLogo = ({addr, ...props}) => <TokenPicture data={`https://dzyb4dm7r8k8w.cloudfront.net/prod/logos/${addr}/logo.png`} type="image/png" {...props}>
</TokenPicture>

export function Main(props) {
    const seiUsdPrice = useCurrentSeiUsdPrice()

    const [docs, setDocs] = useState([])
    const [sortBy, setSortBy] = useState("volume.86400000.total")
    const [sortDesc, setSortDesc] = useState(true)
    const [loadLimit, setLoadLimit] = useState(50)
    const t = 86400000
    const [, navigate] = useLocation();

    useTitle("Slippage")

    useEffect(() => onSnapshot(
        query(poolsRef,
            orderBy(sortBy, sortDesc ? "desc" : "asc"),
            where(sortBy, "not-in", ["-", -1]),
            limit(loadLimit)
        ),
        snap => {
            setDocs(snap.docs)
        }), [sortBy, sortDesc, loadLimit])

    useEffect(() => {
        setLoadLimit(50)
    }, [sortBy, sortDesc])

    const handleHeaderClick = key => {
        if (sortBy === key) {
            setSortDesc(!sortDesc)
        } else {
            setSortBy(key)
            setSortDesc(true)
        }
        setDocs([]);
    };

    const Th = ({w, path, children}) =>
        <StyledTh w={w} clickable={!!path} onClick={() => path && handleHeaderClick(path)}>
            {children} {sortBy === path && (sortDesc ? "▼" : "▲")}
        </StyledTh>

    return <Page>
        <Header/>
        <SpacerXSmall/>
        <TableWrapper>
            <StyledTableVirtuoso
                data={docs}
                fixedHeaderContent={() => <tr>
                    <Th w={"8vw"}>TOKEN</Th>
                    <Th w={"8vw"} path={"price_sei"}>PRICE</Th>
                    <Th w={"8vw"} path={`num_txns.${t}.total`}>TXNS</Th>
                    <Th w={"8vw"} path={`volume.${t}.total`}>VOLUME</Th>
                    <Th w={"8vw"} path={"price_sei_change.300000"}>5M</Th>
                    <Th w={"8vw"} path={"price_sei_change.3600000"}>1H</Th>
                    <Th w={"8vw"} path={"price_sei_change.21600000"}>6H</Th>
                    <Th w={"8vw"} path={"price_sei_change.86400000"}>24H</Th>
                    <Th w={"8vw"} path={"liquidity"}>LIQUIDITY</Th>
                    <Th w={"8vw"} path={"market_cap"}>MKT CAP</Th>
                </tr>}
                components={{
                    TableRow: ({children, ...props}) =>
                        <Tr onClick={() => children.props.children[0].props.onClick()} {...props}>
                            {children}
                        </Tr>
                }}
                itemContent={(index, doc) => {
                    const StatIntervals = [5 * 60 * 1000, 60 * 60 * 1000, 6 * 60 * 60 * 1000, 24 * 60 * 60 * 1000]

                    /**
                     * @type {Pool}
                     */
                    const row = doc.data()
                    return <>
                        <StyledTd onClick={() => navigate(`/token/${doc.id}`)}>
                            <TokenLogo addr={row.token_addr}/>
                            {row.stable_coin && "WSEI / "}{row.token_symbol}
                        </StyledTd>
                        <StyledTd>
                            $<NmAmount num={row.stable_coin ? 1 / row.price_sei : (row.price_sei * seiUsdPrice)}/>
                        </StyledTd>
                        <StyledTd><NmAmount integer={true} num={row.num_txns[t].total}/></StyledTd>
                        <StyledTd>$<NmAmount num={row.volume[t].total}/></StyledTd>
                        {StatIntervals.map(value => <StyledTd>
                                <PriceChange name={""} pool={row} value={value} bold={false}/>
                            </StyledTd>
                        )}
                        <StyledTd>$<NmAmount num={row.liquidity * seiUsdPrice}/></StyledTd>
                        <StyledTd>$<NmAmount num={row.total_supply * row.price_sei * seiUsdPrice}/></StyledTd>
                    </>;
                }}
                endReached={() => setLoadLimit(l => l + 50)}
                increaseViewportBy={400}
                {...props}
            />
        </TableWrapper>
    </Page>
}