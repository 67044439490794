import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getFunctions} from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyACsKO9xW2zhCaC9ThKhHJuwWAaPvnESaw",
    authDomain: "slippage-56704.firebaseapp.com",
    databaseURL: "https://slippage-56704-default-rtdb.firebaseio.com",
    projectId: "slippage-56704",
    storageBucket: "slippage-56704.appspot.com",
    messagingSenderId: "42185039410",
    appId: "1:42185039410:web:d267840b141cea0842a62b",
    measurementId: "G-1YDPKWSP16"
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

/*
connectFunctionsEmulator(functions, "127.0.0.1", 5001);
connectAuthEmulator(user, "http://127.0.0.1:9099");
connectFirestoreEmulator(db, "127.0.0.1", 8080)
*/