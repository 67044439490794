import {range} from "../utils.js";
import spinner from "../assets/black-spinner.svg"

/**
 *
 * @param total {number}
 * @param current {number}
 * @param type {"tick" | "spinner" | "x" | "none"}
 * @param props {SVGAttributes<SVGElement>}
 * @returns {JSX.Element}
 * @constructor
 */
export function Progress({total, current, type = "tick", ...props}) {
    return <svg
        {...props}
        width={`${total * 30 - 12}mm`}
        height="18mm"
        viewBox={`0 0 ${total * 30 - 12} 18`}
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs id="defs1"/>
        <g
            id="layer1"
            style={{
                fill: '#ffffff',
                fillOpacity: 0,
                stroke: '#ffffff',
                strokeWidth: 1.5,
                strokeDasharray: 'none',
                strokeOpacity: 1,
            }}
        >
            {[...range(0, current)].map(i => <>
                <circle
                    style={{fillOpacity: 1}}
                    cx={9 + i * 30}
                    cy={9}
                    r={7.5}
                />
                <path
                    style={{
                        stroke: '#000000',
                        strokeWidth: 1.25,
                    }}
                    d={`M ${9 + i * 30},9 m -3.4387,-0.1341 2.4748,2.4685 4.3579,-5.2590`}
                />
            </>)}
            <>
                <circle
                    style={{fillOpacity: 1}}
                    cx={9 + current * 30}
                    cy={9}
                    r={7.5}
                />
                {{
                    spinner: <image x={2.75 + current * 30} y="2.75" width="12.5" height="12.5" href={spinner}/>,
                    tick: <path
                        style={{
                            stroke: '#000000',
                            strokeWidth: 1.25,
                        }}
                        d={`M ${9 + current * 30},9 m -3.4387,-0.1341 2.4748,2.4685 4.3579,-5.2590`}
                    />,
                    x: <path
                        style={{
                            stroke: '#000000',
                            strokeWidth: 1.25,
                        }}
                        d={`M ${9 + current * 30},9 m -3,-3 6,6 -3,-3 -3,3 6,-6`}
                    />,
                    none: <></>
                }[type]}
            </>
            {[...range(current+1, total)].map(i => <>
                <circle
                    cx={9 + i * 30}
                    cy={9}
                    r={7.5}
                />
            </>)}
            {[...range(1, total)].map(i => <>
                <path d={`M ${1.5 + i * 15 + (i - 1) * 15}, 9 h 15`}/>
            </>)}
        </g>
    </svg>
}