import {useEffect} from "react";

/**
 * @param {Function} fn
 * @param {number} interval
 * @param {*[]} deps
 */
export function useTimeout(fn, interval, deps) {
    useEffect(() => {
        const intervalId = setTimeout(fn, interval)
        return () => clearTimeout(intervalId)
    }, deps)
}