import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {SelectionBar, SelectionOption} from "./SelectionBar.jsx";
import {FancyTextInput} from "../FancyText.jsx";
import {BuySellColors, SpacerMedium, SpacerSmall, SpacerXSmall, TransactionButton} from "../../globalStyles.js";
import {useUserData} from "../../firebase/AuthProvider.jsx";
import useTokenBalance from "../../hooks/useTokenBalance.js";
import useSeiBalance from "../../hooks/useSeiBalance.js";
import {doBuyTransaction, doSellTransaction} from "../../firebase/remoteFunctions.js";
import {useDragonswapQuote} from "../../hooks/useQuotes.js";
import {roundDown} from "../../utils.js";
import {LoadingIcon, Row} from "../transfer/common.jsx";
import white_spinner from "../../assets/tube-spinner.svg";
import black_spinner from "../../assets/black-spinner.svg";
import success from "../../assets/success.svg";
import error from "../../assets/error.svg";
import {useTransaction} from "../../hooks/useTransaction.js";
import {useTimeout} from "../../hooks/useTimeout.js";
import {Amount} from "../Amount.jsx";

export const TRADE_CUT = 0.005 // 0.5%

const Cols = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({jc}) => jc ? jc : "space-evenly"};
    align-items: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: fit-content;
    border-radius: 8px;
    background-color: #1b1c25;
    padding: 17px 24px;
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
`

const StyledOption = styled(SelectionOption)`
    text-align: center;
    flex-grow: 1;
    margin: 0 !important;
    background-color: ${({isSelected, bg}) => isSelected ? bg : "#18181b"};
    border-top: 1px solid #262730;
    border-bottom: 1px solid #262730;

    &:hover {
        background-color: ${({isSelected, hoverBg, bg}) => isSelected ? bg : hoverBg};
    }

    &:first-child {
        border-left: 1px solid #262730;
        border-radius: 8px 0 0 8px !important;
    }

    &:last-child {
        border-radius: 0 8px 8px 0 !important;
        border-right: 1px solid #262730;
    }
`

const PercentButton = styled.button`
    padding: 4px;
    border-radius: 8px;
    font-size: 10px;
    border: none;
    font-weight: bold;
    background-color: #bebab7;
    color: #000;
    width: fit-content;
    margin: 0 4px;
    cursor: pointer;
`;

const StatusIcon = styled(LoadingIcon)`
    margin: -0.25em 0 -0.25em auto;
    height: 1.5em;
    width: 1.5em;
`

const Estigmate = styled.div`
    display: flex;
    justify-items: left;
    height: 1.5em;
`

const opposite = {buy: "sell", sell: "buy"}

/**
 *
 * @param pool {Pool}
 * @param props
 * @returns {Element}
 * @constructor
 */
export function BuySell({pool, ...props}) {
    const [buyOrSell, setBuyOrSell] = useState("buy")
    const [amount, setAmount] = useState("")
    const [slippage, setSlippage] = useState("1")
    const [state, setState] = useState("enabled")
    const [currentTid, setCurrentTid] = useState(null)
    const [quote, setQuote] = useState(null)
    const transaction = useTransaction(currentTid)

    const userData = useUserData()
    const tokenBalance = useTokenBalance(pool?.token_addr, userData?.wallets?.sei_evm, pool?.token_decimals)
    const seiBalance = useSeiBalance(userData?.wallets?.sei)

    const fromParams = {
        "buy": {
            symbol: "SEI",
            balance: seiBalance,
            address: "SEI",
            decimals: 18,
            beforeCut: TRADE_CUT,
            afterCut: 0,
            doTransaction: doBuyTransaction
        },
        "sell": {
            symbol: pool?.token_symbol || "",
            balance: tokenBalance,
            address: pool?.token_addr,
            decimals: pool?.token_decimals ?? 0,
            beforeCut: 0,
            afterCut: TRADE_CUT,
            doTransaction: doSellTransaction
        }
    }

    const {
        balance: fromBalance,
        symbol: fromSymbol,
        address: fromAddress,
        decimals: fromDecimals,
        beforeCut,
        afterCut,
        doTransaction
    } = fromParams[buyOrSell];
    const {symbol: toSymbol, address: toAddress} = fromParams[opposite[buyOrSell]];

    const quoteCont = useDragonswapQuote(
        !isNaN(amount) && (parseFloat(amount) * 10 ** fromDecimals * (1 - beforeCut)),
        fromAddress,
        toAddress,
    );

    useEffect(() => {
        state === "enabled" && setQuote(quoteCont)
    }, [state, quoteCont]);

    const buyOrSellDisabled = state !== "enabled" || !userData || !quote || quote.waiting || fromBalance < parseFloat(amount)

    useEffect(() => {
        if (transaction) {
            if (transaction.current_step === transaction.steps.length) {
                setState("success")
            } else if (transaction.steps[transaction.current_step]?.status === "error") {
                setState("error")
            }
        }
    }, [transaction]);

    useTimeout(() => {
        if (state === "success" || state === "error") {
            setState("enabled")
        }
    }, 1000, [state])

    const handleBuyOrSell = () => {
        if (!buyOrSellDisabled) {
            setState("waiting")
            doTransaction({
                dragonQuery: quote,
                slippageBps: parseFloat(slippage) * 100, // "5" is 5% is 500bps
                tokenAddress: pool.token_addr,
            }).then(setCurrentTid).catch(() => setState(true))
        }
    };

    const handlePercentClicked = ratio =>
        () => setAmount(roundDown(fromBalance * ratio, 0.01).toFixed(2))

    return <Container {...props}>
        <SelectionBar p={"16px 0"} value={buyOrSell} setValue={setBuyOrSell}>
            <StyledOption value={"buy"} selected={"#000"} {...BuySellColors.buy}>BUY</StyledOption>
            <StyledOption value={"sell"} selected={"#000"} {...BuySellColors.sell}>SELL</StyledOption>
        </SelectionBar>
        <div style={{marginRight: "auto"}}>
            BALANCE: <span style={{fontWeight: "bold"}}>
                <Amount num={fromBalance ?? "..."}/> {!isNaN(fromBalance) && fromSymbol}
            </span>
        </div>
        <SpacerSmall/>
        <FancyTextInput style={{width: "unset"}} fontSize={"12px"} label={`Amount to ${buyOrSell} in ${fromSymbol}`}
                        value={amount}
                        setValue={setAmount}/>
        <SpacerSmall/>
        <Cols jc={"space-between"}>
            <Cols>
                <PercentButton onClick={handlePercentClicked(0.25)}>25%</PercentButton>
                <PercentButton onClick={handlePercentClicked(0.50)}>50%</PercentButton>
                <PercentButton onClick={handlePercentClicked(0.75)}>75%</PercentButton>
                <PercentButton onClick={handlePercentClicked(1)}>100%</PercentButton>
            </Cols>
            <Cols>
                <div style={{marginRight: "4px", fontSize: "12px"}}>Slippage:</div>
                <FancyTextInput
                    style={{width: "1.5rem", padding: "4px 8px"}}
                    fontSize={"12px"}
                    value={slippage}
                    setValue={setSlippage}
                    postfix={"%"}
                />
            </Cols>
        </Cols>
        <SpacerMedium/>
        <TransactionButton
            w={"100%"}
            onClick={handleBuyOrSell}
            disabled={buyOrSellDisabled}
            {...BuySellColors[buyOrSell]}
        >
            <Row fb={0} fg={1}/>
            <Row>{buyOrSell.toUpperCase()}</Row>
            <Row fb={0} fg={1}>
                {{
                    enabled: <></>,
                    waiting: <StatusIcon src={black_spinner} alt={state}/>,
                    success: <StatusIcon src={success} alt={state}/>,
                    error: <StatusIcon src={error} alt={state}/>
                }[state]}
            </Row>
        </TransactionButton>
        <SpacerXSmall/>
        <Estigmate>Estimated value: <span style={{fontWeight: "bold", marginLeft: "0.25em"}}>
            {(quote?.waiting && <LoadingIcon s={"1.25em"} m={""} src={white_spinner} alt="loading"/>)
                || (quote?.quoteGasAdjustedDecimals && <>
                    <Amount num={(quote?.quoteGasAdjustedDecimals * (1 - afterCut))}/> {toSymbol}
                </>)
                || "..."
            }
        </span>
        </Estigmate>
    </Container>
}