import {Description, Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import styled from "styled-components";
import {SpacerGrow, SpacerMedium} from "../../globalStyles.js";

const CustomDialog = styled(Dialog)`
    position: relative;
    z-index: 50;
`

const DialogContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100vw; /* Full width of the viewport */
    height: 100vh;
    align-items: center; /* Vertically centers the content */
    justify-content: center; /* Horizontally centers the content */
    background-color: rgba(0, 0, 0, 0.5);
`

const CustomDialogPanel = styled(DialogPanel)`
    max-width: 75vw; /* Tailwind's max-w-lg corresponds to 32rem (512px) */
    padding: 3rem 4rem; /* 48px padding (Tailwind's p-12 is equivalent to 3rem) */
    background-color: #1b1c25; /* Sets the background color to white */
    color: white;
    text-align: center;
    border-radius: 32px;
    border: 1px solid #262730FF;
`
const ButtonContainer = styled.div`
    display: flex; /* Applies flexbox layout */
    justify-content: space-evenly;
    gap: 1rem; /* 16px space between flex items (Tailwind's gap-4 is equivalent to 1rem) */
`

const Button = styled.button`
    background-color: ${({c}) => c};
    color: black;
`

/**
 *
 * @param info {string | false | null}
 * @param onCancel {()=>{}}
 * @param onConfirm {()=>{}}
 * @returns {JSX.Element}
 * @constructor
 */
export default function TransferConfirmDialog({message, subMessage, showWarning, showFeeWarning, onCancel, onConfirm}) {
    return <CustomDialog open={!!message} onClose={onCancel}>
        <DialogContainer>
            <SpacerGrow fg={1}/>
            <CustomDialogPanel>
                <DialogTitle style={{fontWeight: 'bold'}}>
                    {message}
                </DialogTitle>
                {subMessage && <Description>{subMessage}</Description>}
                <Description style={{fontSize: "0.75em", fontStyle: "italic"}}>
                    {showWarning && "Please note that the final amount may vary based on current market prices."}
                    {showWarning && showFeeWarning && <br/>}
                    {showFeeWarning && "0.2 SEI will be allocated to cover blockchain fees during your trading activity."}
                </Description>
                <SpacerMedium/>
                <ButtonContainer>
                    <Button c={"#bebab7"} onClick={onCancel}>Cancel</Button>
                    <Button c={"#6fd3cb"} onClick={onConfirm}>Confirm</Button>
                </ButtonContainer>
            </CustomDialogPanel>
            <SpacerGrow fg={1}/>
        </DialogContainer>
    </CustomDialog>
}