import {TableVirtuoso} from "react-virtuoso";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {collection, doc, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../firebase/firebase-config.js";
import {
    Page,
    SpacerAuto,
    SpacerGrow,
    SpacerXSmall,
    TextDescription,
    TextSubText,
    TextSubTitle,
    TextTitle,
    UnstyledLink
} from "../globalStyles.js";
import {Header} from "../components/header/Header.jsx";
import {Amount} from "../components/Amount.jsx";
import {useUserData} from "../firebase/AuthProvider.jsx";
import {Col, LoadingIcon, Row} from "../components/transfer/common.jsx";
import link from "../assets/external-link.svg";
import {CopyableText} from "../components/FancyText.jsx";
import {useTitle} from "../hooks/useTitle.js";
import {useUserTokens} from "../hooks/useUserTokens.js";
import {Checkbox} from "../components/Checkbox.jsx";
import {useCurrentSeiUsdPrice} from "../firebase/CurrentSeiPriceProvider.jsx";


const TableWrapper = styled.div`
    flex-grow: 1;
    padding-top: 8px;
    display: flex;
`

const Container = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
    border-radius: 8px;
    background-color: #1c1c25;
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
`

const StyledTableVirtuoso = styled(TableVirtuoso)`
    width: 100%;
    height: unset !important;

    & table {
        width: 100%;
    }

    & thead {
        z-index: unset !important;
    }
`

const StyledTd = styled.td`
    color: ${({c}) => c || ""};
    padding: 4px;
    font-size: 20px;
`

const Tr = styled.tr`
    background-color: ${({bg}) => bg ?? "#2f2f3e"};
`

const Th = styled.th`
    background-color: #1b1c25;
    padding: 6px;
    width: ${({w}) => w || ""};
`

const StyledLink = styled(UnstyledLink)`
    display: flex;
    align-items: center;
    align-content: center;
    width: fit-content;
    margin: auto;
`

const leaderboardRef = collection(db, "leaderboard");
const poolsRef = collection(db, "pools");

export function Profile(props) {
    useTitle("Profile")
    const seiUsdPrice = useCurrentSeiUsdPrice()
    const userData = useUserData()
    const {tokens} = useUserTokens()
    const [myRecord, setMyRecord] = useState(null)
    const [tokenRows, setTokenRows] = useState([])
    const [hideSmallTokens, setHideSmallTokens] = useState(true)
    console.log(tokens)
    useEffect(() => userData?.leaderboard_id && onSnapshot(
        doc(leaderboardRef, userData?.leaderboard_id),
        snap => {
            setMyRecord(snap.data())
        }), [userData])

    useEffect(() => Object.keys(tokens).length > 0 && onSnapshot(
        query(poolsRef,
            where("token_addr", "in", Object.keys(tokens)),
        ),
        snap => {
            setTokenRows(snap.docs
                .map(doc => doc.data())
                .map(pool => {
                    const balance = tokens[pool.token_addr] * 10 ** -pool.token_decimals
                    return {...pool, balance, usd_value: balance * pool.price_sei * seiUsdPrice}
                })
            )
        }) || undefined, [tokens])
    const sorted_tokens = tokenRows
        .filter(({usd_value}) => usd_value > 0 && !hideSmallTokens || usd_value > 1)
        .sort(({usd_value: a}, {usd_value: b}) => b - a)


    return <Page>
        <Header/>
        <SpacerXSmall/>
        <Row fg={1} g={"8px"}>
            <Col fg={1} fb={0} g={"8px"}>
                <Row w={"100%"} fg={1} g={"8px"}>
                    <Container>
                        <TextTitle>MY POINTS</TextTitle>
                        <TextTitle fs={"72px"}><Amount num={myRecord?.total_score ?? "-"}/></TextTitle>
                        <TextSubTitle w={"bold"} ta={"left"}>Ways to Earn Points</TextSubTitle>
                        <TextDescription>
                            1. Trade: Earn 10 points for every $1,000 traded
                        </TextDescription>
                        <TextDescription>
                            2. Refer a Friend: Earn 10 points when your friend completes trades totaling over $100
                        </TextDescription>
                        <TextDescription>
                            3. dob Bonus: If you're a dob holder, earn 20 points for every $1,000 traded (You must be
                            logged in with a SEI wallet)
                        </TextDescription>
                    </Container>
                </Row>
                <Row w={"100%"} fg={1} g={"8px"}>
                    <Container>
                        <TextTitle>REFERRAL</TextTitle>
                        <SpacerGrow fg={1}/>
                        <TextDescription>
                            <CopyableText
                                m={"0 auto"}
                                length={-1}
                                text={userData && `https://slippage.trade/referral/${userData.leaderboard_id}` || ""}
                            />
                        </TextDescription>
                        <SpacerGrow fg={2}/>
                    </Container>
                </Row>
            </Col>
            <Container fb={0}>
                <Row>
                    <Col fb={0} fg={1}></Col>
                    <TextTitle>MY TOKENS</TextTitle>
                    <Col fb={0} fg={1}>
                        <Row w={"100%"}>
                            <SpacerAuto/>
                            <Checkbox checked={hideSmallTokens} onClick={() => setHideSmallTokens(t => !t)}/>
                            <TextSubText onClick={() => setHideSmallTokens(t => !t)}>{"Hide <1$"}</TextSubText>
                        </Row>
                    </Col>
                </Row>
                <TableWrapper>
                    <StyledTableVirtuoso
                        data={sorted_tokens}
                        fixedHeaderContent={() => <tr>
                            <Th w={"20%"}>TOKEN</Th>
                            <Th w={"20%"}>BALANCE</Th>
                            <Th w={"20%"}>VALUE</Th>
                            <Th w={"20%"}>BUY/SELL</Th>
                        </tr>}
                        itemContent={(i, token) => {
                            return <>
                                <StyledTd>{token.token_symbol}</StyledTd>
                                <StyledTd>
                                    <Amount num={token.balance}/>
                                </StyledTd>
                                <StyledTd>
                                    $<Amount num={token.usd_value}/>
                                </StyledTd>
                                <StyledTd>
                                    <StyledLink to={`/token/${token.address}`}>
                                        <LoadingIcon src={link}/>
                                    </StyledLink>
                                </StyledTd>
                            </>;
                        }}
                        increaseViewportBy={400}
                        {...props}
                    />
                </TableWrapper>
            </Container>
        </Row>
    </Page>
}