import {Page, SpacerAuto, SpacerGrow, SpacerMedium, TextSubTitle, UnstyledLink} from "../globalStyles.js";
import styled from "styled-components";
import React from "react";

const Bar = styled.nav`
    font-size: 25px;
    overflow: clip;
    max-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    margin: -8px -8px 0;
    padding: 12px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #262730;
    background-color: #1b1c25;
    ${({displayNav}) => displayNav ? "background: #18181b" : ""};
`

const MainNav = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
`
const NavLi = styled.li`
    align-content: center;
    display: flex;
    justify-content: center;
    flex: 1;
`

const ProductName = styled(UnstyledLink)`
    height: 36px;
`

const CenteringDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 75vw;
    width: fit-content;
    margin: 0 auto;
    background: #1b1c25;
    border-radius: 32px;
    padding: 32px;
    border: 1px solid #262730FF;
`

const MobilePage = styled(Page)`
    @media (min-width: 767px) {
        display: none
    }
`

export function Mobile(props) {
    return (
        <MobilePage>
            <Bar>
                <MainNav>
                    <NavLi>
                        <ProductName href={"/"}>
                            slippage
                        </ProductName>
                        <SpacerAuto/>
                    </NavLi>
                </MainNav>
            </Bar>
            <SpacerMedium/>
            <CenteringDiv>
                <SpacerGrow fg={2}/>
                <MessageContainer>
                    <TextSubTitle w={"bold"}>For the best experience, visit our website on desktop.</TextSubTitle>
                </MessageContainer>
                <SpacerGrow fg={3}/>
            </CenteringDiv>
        </MobilePage>
    )
}