import {TableVirtuoso} from "react-virtuoso";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {collection, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../firebase/firebase-config.js";
import {useFirebaseAuth} from "../../firebase/AuthProvider.jsx";
import {retryTransaction} from "../../firebase/remoteFunctions.js";
import {Progress} from "../Progress.jsx";
import {SpacerGrow, StyledButton} from "../../globalStyles.js";
import {Row} from "./common.jsx";
import {Amount} from "../Amount.jsx";

const StyledTableVirtuoso = styled(TableVirtuoso)`
    height: 300px !important;
    width: 100%;
    border-radius: 16px;
    background-color: #1b1c25;

    & table {
        width: 100%;
    }
`

const StyledTd = styled.td`
    color: ${({c}) => c || ""};
    padding: 4px;
    font-size: 14px;
`

const StyledTh = styled.th`
    background-color: #1b1c25;
    padding: 6px;
    width: ${({w}) => w || ""};
`

const RetryButton = styled(StyledButton)`
    padding: 8px 12px;
    font-size: inherit;
`

const transactionsRef = collection(db, "cloudTransactions");

/**
 * @param {"waiting" | "pending" | "success" | "error" | "retry"} status
 */
function getTickTypeForStatus(status) {
    switch (status) {
        case "waiting":
            return "none";
        case "pending":
        case "retry":
            return "spinner";
        case "success":
            return "tick";
        case "error":
            return "x";
    }
}

const transactionTypes = {
    "SolanaDepositTransaction": {denom: "SOL", name: "Deposit Sol", mult: 1e-9},
    "SolanaWithdrawTransaction": {denom: "SEI", name: "Withdraw Solana USDC", mult: 1},
    "SeiDepositTransaction": {denom: "SEI", name: "Deposit Sei", mult: 1},
    "SeiWithdrawTransaction": {denom: "SEI", name: "Withdraw Sei", mult: 1},
}

export function HistoryTable(props) {
    const [rawDocs, setRawDocs] = useState([])
    const [docs, setDocs] = useState([])
    const user = useFirebaseAuth()
    const [loadLimit, setLoadLimit] = useState(50)

    useEffect(() => onSnapshot(
        query(transactionsRef,
            where("uid", "==", user?.uid),
            where("type", "in", Object.keys(transactionTypes)),
            orderBy("timestamp", "desc"),
            limit(loadLimit)
        ),
        snap => {
            setRawDocs(snap.docs)
        }), [loadLimit])

    useEffect(() => {
        const id = setTimeout(() => {
            setDocs(rawDocs)
        }, 500)
        return () => clearTimeout(id)
    }, [rawDocs]);

    const handleRetry = (transaction, tid) => {
        retryTransaction({tid, type: transaction.type}).then()
    };

    return <StyledTableVirtuoso
        data={docs}
        fixedHeaderContent={() => <tr>
            <StyledTh w={"25%"}>Date</StyledTh>
            <StyledTh w={"25%"}>Type</StyledTh>
            <StyledTh w={"25%"}>Amount</StyledTh>
            <StyledTh w={"25%"}>Status</StyledTh>
        </tr>}
        itemContent={(index, doc) => {
            /**
             * @type {TransactionRecord}
             */
            const row = doc.data()
            const {name, denom, mult} = transactionTypes[row.type] ?? {name: "", denom: "", mult: 0}
            return <>
                <StyledTd>{new Date(row.timestamp).toLocaleString()}</StyledTd>
                <StyledTd>{name}</StyledTd>
                <StyledTd>
                    <Amount num={parseFloat(JSON.parse(row.parameters).amount) * mult || ""}/>{denom}
                </StyledTd>
                <StyledTd>
                    <Row g={"8px"}>
                        <SpacerGrow fg={1}/>
                        <Progress style={{height: "2rem", width: "auto"}}
                                  total={row.steps.length}
                                  current={row.current_step}
                                  type={getTickTypeForStatus(row.steps[row.current_step]?.status)}
                        />
                        {row.steps[row.current_step]?.status === "error" &&
                            <RetryButton onClick={() => handleRetry(row, doc.id)}>Retry</RetryButton>
                        }
                        <SpacerGrow fg={1}/>
                    </Row>
                </StyledTd>
            </>;
        }}
        endReached={() => setLoadLimit(l => l + 20)}
        increaseViewportBy={400}
        {...props}
    />
}