import {useEffect, useState} from "react";
import {db} from "../firebase/firebase-config.js";
import {collection, limit, onSnapshot, query, where} from "firebase/firestore";
import {useFirebaseAuth} from "../firebase/AuthProvider.jsx";

/**
 *
 * @param txTypes {string[]?}
 * @returns {TransactionRecord | undefined}
 */
export const useOngoingTransaction = (txTypes) => {
    const [transaction, setTransaction] = useState(null);
    const auth = useFirebaseAuth()

    useEffect(() => txTypes && auth?.uid && onSnapshot(
        query(
            collection(db, "cloudTransactions"),
            where("uid", "==", auth.uid),
            where("type", "in", txTypes),
            where("status", "==", "in-progress"),
            limit(1)
        ),
        snap => {
            setTransaction(snap.empty ? null : snap.docs[0].data())
        }) || undefined, [txTypes])

    return transaction
}