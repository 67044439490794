import bs58 from "bs58";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {ConnectionProvider, useWallet as useSolanaWallet, WalletProvider} from "@solana/wallet-adapter-react";
import {SeiWalletContext, SeiWalletProvider} from "@sei-js/react";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {clusterApiUrl, Connection, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {PhantomWalletAdapter} from "@solana/wallet-adapter-wallets";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {
    doSeiDepositTransaction,
    doSeiWithdrawTransaction,
    doSolanaDepositTransaction,
    doSolanaWithdrawTransaction
} from "./remoteFunctions.js";
import {getSigningStargateClient} from "@sei-js/cosmjs";
import {calculateFee} from '@cosmjs/stargate';
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui";

/**
 * @typedef {import("@sei-js/core").SeiWallet} SeiWallet
 * @typedef {import("@solana/wallet-adapter-react").WalletContextState} WalletContextState
 * @typedef {import('@cosmjs/amino').StdSignature} StdSignature
 */

class CryptoConnection {
    /**
     * @type {string}
     */
    protocol
    /**
     * @type {string}
     */
    address

    constructor(protocol, address) {
        this.protocol = protocol;
        this.address = address;
    }

    /**
     * @param {string} message
     * @returns {Promise<string | StdSignature>}
     */
    async sign(message) {
        throw new Error("Sign method is not available");
    }

    /**
     * @param {number} amount
     * @param {SolToSeiQuote | {waiting: true} | null} depositQuote
     * @return {Promise<string | null>}
     * */
    async depositFromWallet(amount, depositQuote) {
        throw new Error("depositFromWallet method is not available");
    }

    /**
     * @param {number} amount
     * @param {SeiToSolQuote | {waiting: true} | null} withdrawQuote
     * @return {Promise<string | null>}
     * */
    async withdrawToWallet(amount, withdrawQuote) {
        throw new Error("withdrawToWallet method is not available");
    }
}

class SolanaConnection extends CryptoConnection {
    /**
     * @type {WalletContextState}
     */
    wallet

    constructor(solanaWallet) {
        super("solana", solanaWallet.publicKey.toString());
        this.wallet = solanaWallet;
    }

    async sign(message) {
        try {
            const data = new TextEncoder().encode(message)
            const signature = await this.wallet.signMessage(data);
            return bs58.encode(signature);
        } catch (error) {
            await this.wallet.disconnect()
            throw error
        }
    }

    async depositFromWallet(amount, depositQuote, userData) {
        if (depositQuote && !depositQuote.waiting) {
            const connection = new Connection(
                "https://powerful-lingering-shape.solana-mainnet.quiknode.pro/8f7213203fd12a9f07a95e664777aed8f3b8b4df/",
                "confirmed",
            );

            const transferTransaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: this.wallet.publicKey,
                    toPubkey: new PublicKey(userData.wallets.solana),
                    lamports: 1000000000 * amount,
                }),
            );
            await this.wallet.sendTransaction(transferTransaction, connection)
            return await doSolanaDepositTransaction({amount: amount * 1000000000}).catch(console.error)
        }
        return null;
    }

    async withdrawToWallet(amount, withdrawQuote) {
        if (withdrawQuote && !withdrawQuote.waiting) {
            return await doSolanaWithdrawTransaction({
                amount,
                targetWallet: this.address
            })
        }
        return null;
    }
}


class SeiConnection extends CryptoConnection {
    /**
     * @type {SeiWallet}
     */
    wallet

    /**
     * @param {string} address
     * @param {SeiWallet} seiWallet
     */
    constructor(address, seiWallet) {
        super("sei", address);
        this.wallet = seiWallet;
    }

    async sign(message) {
        return await this.wallet.signArbitrary("pacific-1", this.address, message);
    }

    async depositFromWallet(seiAmount, depositQuote, userData) {
        const amount = {
            "denom": "usei",
            "amount": Math.floor(seiAmount * 1000000).toString()
        }
        const signer = await this.wallet.getOfflineSigner("pacific-1")
        const signingClient = await getSigningStargateClient("https://rpc.sei-apis.com", signer);

        const fee = calculateFee(100000, "0.1usei");
        const resp = await signingClient.sendTokens(this.address, userData.wallets.sei, [amount], fee)
        if (resp.code !== 0) {
            throw new Error("Transaction failed", {cause: resp});
        }
        console.log(`Sent sei from user with transaction ${resp.transactionHash}`)
        await doSeiDepositTransaction({amount: seiAmount});
        return null
    }

    async withdrawToWallet(amount) {
        return await doSeiWithdrawTransaction({
            targetWallet: this.address,
            amount,
        })
    }
}

const NO_CONNECTION = new CryptoConnection("none", "")

/**
 * @type {React.Context<CryptoConnection>}
 */
const CryptoConnectionContext = createContext(NO_CONNECTION)

export const useCryptoConnection = () => useContext(CryptoConnectionContext);

function MyConnectionProvider({children}) {
    const [connection, setConnection] = useState(NO_CONNECTION)
    const solanaWallet = useSolanaWallet();
    const {
        connectedWallet: seiWallet,
        setTargetWallet,
        wallets
    } = useContext(SeiWalletContext);

    useEffect(() => {
        const windowKey = localStorage.getItem("lastSeiWalletId")
        if (windowKey && windowKey !== "") {
            setTargetWallet(wallets.filter(w => w.walletInfo.windowKey === windowKey)[0])
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("lastSeiWalletId", connection instanceof SeiConnection ? connection.wallet.walletInfo.windowKey : "");
    }, [connection]);

    useEffect(() => {
        (async () => {
            const isSolana = solanaWallet.connected && !solanaWallet.disconnecting
            const isSei = !!seiWallet
            try {
                if (isSolana && !isSei) {
                    setConnection(new SolanaConnection(solanaWallet))
                } else if (isSei && !isSolana) {
                    const address = (await seiWallet.getAccounts("pacific-1"))[0].address
                    setConnection(new SeiConnection(address, seiWallet))
                } else {
                    setConnection(NO_CONNECTION)
                }
            } catch (error) {
                console.error(error)
                await solanaWallet.disconnect().catch(console.error)
                await seiWallet.disconnect("pacific-1").catch(console.error)
                setConnection(NO_CONNECTION)
            }
        })().then()
    }, [solanaWallet, seiWallet])

    return <CryptoConnectionContext.Provider value={connection}>
        {children}
    </CryptoConnectionContext.Provider>
}

const queryClient = new QueryClient()

export function CryptoConnectionProvider({children}) {
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
        ],
        []
    );

    return <SeiWalletProvider
        chainConfiguration={{
            chainId: 'atlantic-2',
            restUrl: 'https://rest.sei-apis.com/',
            rpcUrl: 'https://rpc.sei-apis.com'
        }}
        wallets={['compass', 'leap', 'fin', 'keplr']}
    >
        <QueryClientProvider client={queryClient}>
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect={true}>
                    <WalletModalProvider>
                        <MyConnectionProvider>
                            {children}
                        </MyConnectionProvider>
                    </WalletModalProvider>
                </WalletProvider>
            </ConnectionProvider>
        </QueryClientProvider>
    </SeiWalletProvider>
}


