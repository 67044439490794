import React, {useState} from 'react';
import styled from 'styled-components';
import {SpacerAuto, UnstyledLink} from "../../globalStyles.js";
import {SearchBar} from "./SearchBar.jsx";
import {useAuthStatus} from "../../firebase/AuthProvider.jsx";
import {ConnectButton} from "./ConnectButton.jsx";
import {UserDetails} from "./UserDetails.jsx";
import {LoadingIcon, Row} from "../transfer/common.jsx";
import spinner from "../../assets/tube-spinner.svg";

const Bar = styled.nav`
    font-size: 25px;
    padding: 0 8px 8px;
    overflow: clip;
    max-height: 100vh;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
        margin: -8px -8px 0;
        padding: 12px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #262730;
        background-color: #1b1c25;
    }
    ${({displayNav}) => displayNav ? "background: #18181b" : ""};
`

const MainNav = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
        background: #3f3f3f;
        display: ${({displayNav}) => displayNav ? "flex" : "none"};
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0 16px 16px;
        height: 100vh;
    }
`
const NavLi = styled.li`
    align-content: center;
    display: flex;
    justify-content: center;
    flex: 1;

    @media (max-width: 768px) {
        border-bottom: 1px solid #aaa;
    }

    &:first-child {
        @media (max-width: 768px) {
            border-top: 1px solid #aaa;
            margin-top: 8px;
        }
    }
`

const ProductName = styled(UnstyledLink)`
    height: 36px;
`

const NavBarToggle = styled.span`
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
`

const Hamburger = styled.img`
    content: url(data:image/svg+xml,%3Csvg%20height%3D%2232px%22%20id%3D%22Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2032%2032%3B%22%20version%3D%221.1%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232px%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cpath%20d%3D%22M4%2C10h24c1.104%2C0%2C2-0.896%2C2-2s-0.896-2-2-2H4C2.896%2C6%2C2%2C6.896%2C2%2C8S2.896%2C10%2C4%2C10z%20M28%2C14H4c-1.104%2C0-2%2C0.896-2%2C2%20%20s0.896%2C2%2C2%2C2h24c1.104%2C0%2C2-0.896%2C2-2S29.104%2C14%2C28%2C14z%20M28%2C22H4c-1.104%2C0-2%2C0.896-2%2C2s0.896%2C2%2C2%2C2h24c1.104%2C0%2C2-0.896%2C2-2%20%20S29.104%2C22%2C28%2C22z%22%2F%3E%3C%2Fsvg%3E);
    @media (min-width: 768px) {
        display: none;
    }
`

export function Header({className}) {
    let [displayNav, setDisplayNav] = useState(false)
    const toggleNavBar = () => setDisplayNav(!displayNav)
    const authStatus = useAuthStatus()

    return <Bar className={className} displayNav={displayNav}>
        <NavBarToggle onClick={() => toggleNavBar()}>
            <Hamburger/>
        </NavBarToggle>
        <MainNav displayNav={displayNav}>
            <NavLi>
                <ProductName href={"/"}>
                    slippage
                </ProductName>
                <SpacerAuto/>
            </NavLi>
            <NavLi>
                <SearchBar/>
            </NavLi>
            <NavLi>
                <SpacerAuto/>
                {
                    {
                        "wallet-disconnected": <ConnectButton/>,
                        "in-progress": <Row>connecting <LoadingIcon src={spinner} alt="loading"/></Row>,
                        "signed-in": <UserDetails/>
                    }[authStatus]
                }
            </NavLi>
        </MainNav>
    </Bar>
}