import {useWalletModal} from "@solana/wallet-adapter-react-ui";
import React, {useCallback, useRef, useState} from "react";
import {useSelectWallet} from "@sei-js/react";

import {UnstyledButton} from "../../globalStyles.js";
import {Container, Dialogue, DialogueRow, Icon, IconBox, useOnClickOutside} from "./Dialog.jsx";
import sol_logo from "../../assets/sol_logo.webp";
import sei_logo from "../../assets/sei_logo.png";


export function ConnectButton() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const {visible, setVisible} = useWalletModal();
    const {openModal} = useSelectWallet()
    const ref = useRef(null);

    useOnClickOutside(ref, useCallback(() => setDialogOpen(false), []));

    const handleSolanaClick = useCallback(
        (event) => {
            if (!event.defaultPrevented) setVisible(!visible);
        },
        [setVisible, visible]
    );

    return <Container ref={ref}>
        <UnstyledButton onClick={() => setDialogOpen(d => !d)}>
            connect wallet
        </UnstyledButton>
        <Dialogue visible={dialogOpen}>
            <DialogueRow onClick={handleSolanaClick}>
                <IconBox>
                    <Icon src={sol_logo} alt={"solana"}/>
                </IconBox>
                <UnstyledButton>
                    Solana
                </UnstyledButton>
            </DialogueRow>
            <DialogueRow onClick={() => openModal()}>
                <IconBox>
                    <Icon src={sei_logo} alt={"sei"}/>
                </IconBox>
                <UnstyledButton>
                    Sei
                </UnstyledButton>
            </DialogueRow>
        </Dialogue>
    </Container>
}