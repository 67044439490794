import {useEffect, useState} from "react";
import {db} from "../firebase/firebase-config.js";
import {doc, onSnapshot} from "firebase/firestore";
import {useUserData} from "../firebase/AuthProvider.jsx";
import {ethers} from "ethers";
import abi from "./erc20_abi.json";

const provider = new ethers.JsonRpcProvider("https://evm-rpc.sei-apis.com/");

/**
 *
 * @returns {UserTokensRecord}
 */
export const useUserTokens = () => {
    const [userTokens, setUserTokens] = useState({tokens: {}});
    const userData = useUserData()

    useEffect(() => userData && onSnapshot(
        doc(db, "userTokens", userData.wallets.sei),
        snap => {
            const rawTokens = snap.data()?.tokens ?? {}
            Promise.all(Object
                .keys(rawTokens)
                .map(async k => ([
                    k,
                    Number(await new ethers.Contract(k, abi, provider).balanceOf(userData.wallets.sei_evm))
                ])))
                .then(arr => Object.fromEntries(arr))
                .then(tokens => setUserTokens({tokens}))
        }) || undefined, [userData])

    return userTokens
}