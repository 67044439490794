import {createContext, useContext, useEffect, useState} from "react";
import {onAuthStateChanged, signInWithCustomToken, signOut} from "firebase/auth";
import {auth} from "./firebase-config.js";
import {getNonceToSign, getSigninToken} from "./remoteFunctions.js";
import {useLocation} from "wouter";
import {useWallet as useSolanaWallet} from "@solana/wallet-adapter-react";
import {useWallet as useSeiWallet} from "@sei-js/react";
import {useCryptoConnection} from "./CryptoConnectionProvider.jsx";

/**
 * @param {string} protocol
 * @param {string} address
 * @param {string | StdSignature} signature
 * @param {string} referrer
 * @returns {Promise<void>}
 */
async function signInWithSignature(protocol, address, signature, referrer_id) {
    const {token} = await getSigninToken({
        address,
        signature,
        protocol,
        referrer_id,
    });

    await signInWithCustomToken(auth, token)
}

/**
 * @type {React.Context<import("@firebase/auth").User | null>}
 */
const FirebaseAuthContext = createContext(null);

/**
 * @type {React.Context<"wallet-disconnected" | "in-progress" | "signed-in">}
 */
const AuthStatusContext = createContext("wallet-disconnected");

/**
 * @type {React.Context<PrivateUserData | UserData | null>}
 */
const UserDataContext = createContext(null)

const SetReferrerContext = createContext(referrer => {
})

export const useFirebaseAuth = () => useContext(FirebaseAuthContext);
export const useAuthStatus = () => useContext(AuthStatusContext);
export const useUserData = () => useContext(UserDataContext)
export const useSetReferrer = () => useContext(SetReferrerContext)

export const FirebaseAuthProvider = ({children}) => {
    const [, navigate] = useLocation();
    const [firebaseUser, setFirebaseUser] = useState(auth.currentUser);
    const [authStatus, setAuthStatus] = useState("wallet-disconnected");
    const [userData, setUserData] = useState(null)
    const [didFirstLogin, setDidFirstLogin] = useState(true)
    const [referrer, setReferrer] = useState(null)
    const connection = useCryptoConnection()
    const solanaWallet = useSolanaWallet();
    const seiWallet = useSeiWallet()

    useEffect(() => onAuthStateChanged(auth, setFirebaseUser), []);

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            if (connection.protocol === "none") {
                if (authStatus === "signed-in") {
                    console.log("Wallets disconnected even though signed in.", connection)
                    await signOut(auth)
                    setAuthStatus("wallet-disconnected")
                    navigate("/")
                }
            } else if (firebaseUser) {
                const [fb_protocol, fb_address] = firebaseUser.uid.split("_")
                if (connection.protocol === fb_protocol && connection.address === fb_address) {
                    setAuthStatus("signed-in")
                } else {
                    console.log(`Wallet ${(connection.protocol)} ${(connection.address)} doesn't match account ${firebaseUser.uid}`)
                    await signOut(auth)
                    setAuthStatus("wallet-disconnected")
                }
            } else {
                if (authStatus === "wallet-disconnected") {
                    setAuthStatus("in-progress")
                    try {
                        setDidFirstLogin(false)
                        const nonce = await getNonceToSign({address: connection.address, protocol: connection.protocol})
                        const msg = "Signing in to slippage." + nonce;
                        const signature = await connection.sign(msg);
                        await signInWithSignature(connection.protocol, connection.address, signature, referrer);
                    } catch (error) {
                        console.error(error)
                        seiWallet.disconnect()
                        await solanaWallet.disconnect()
                        setAuthStatus("wallet-disconnected");
                    }
                } else if (authStatus === "signed-in") {
                    setAuthStatus("wallet-disconnected")
                }
            }
        }, 1000)
        return () => clearTimeout(timeoutId)
    }, [connection, authStatus, firebaseUser])

    useEffect(() => {
        if (authStatus === "signed-in") {
            (async () => {
                const token = await firebaseUser?.getIdTokenResult()
                setUserData(token?.claims)
                if (token?.claims?.firstLogin && !didFirstLogin) {
                    setDidFirstLogin(true)
                    navigate("/onboarding")
                }
            })()
        } else {
            setUserData(null)
        }
    }, [firebaseUser, authStatus]);

    return <FirebaseAuthContext.Provider value={firebaseUser}>
        <AuthStatusContext.Provider value={authStatus}>
            <UserDataContext.Provider value={userData}>
                <SetReferrerContext.Provider value={setReferrer}>
                    {children}
                </SetReferrerContext.Provider>
            </UserDataContext.Provider>
        </AuthStatusContext.Provider>
    </FirebaseAuthContext.Provider>
}