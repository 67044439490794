import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CandlestickSeries, Chart, TimeScale} from 'lightweight-charts-react-wrapper';
import {
    endBefore,
    getDatabase,
    limitToFirst,
    limitToLast,
    onValue,
    orderByKey,
    query,
    ref,
    startAfter
} from "firebase/database";
import {DefaultDict, mergeTimeData, roundDown} from "../../utils.js";

const numbers = ["₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉"]
export const subscriptify = n => numbers.reduce((acc, cur, i) => acc.replace(i.toString(), cur), n.toString())

/**
 * @param {number} num
 * @returns {string}
 */
export const formatPrice = num => num
    .toPrecision(5)
    .replace(/\.(000+)([1-9]\d*?)$/, (match, zeros, rest) => `.0${subscriptify(zeros.length)}${rest}`)

// important that we sort from large to small
const availableIntervals = [24 * 60 * 60 * 1000, 60 * 60 * 1000, 60 * 1000]
const getAvailable = interval => availableIntervals.filter(ai => ai <= interval)[0]


export function PoolChart({pool, interval, type, stable_coin, ...props}) {
    const [fwdTimeRange, setFwdTimeRange] = useState({point: Date.now(), length: 0})
    const [backTimeRange, setBackTimeRange] = useState({point: Date.now(), length: 100})
    const [rawData, setRawData] = useState({data: {}, interval: 0})
    const [barsData, setBarsData] = useState([]);

    const [height, setHeight] = useState(300)
    const [width, setWidth] = useState(600)

    const chartContainer = useRef(null);
    const timeScale = useRef(null)
    const candleSeries = useRef(null);
    const timer = useRef(null);

    useEffect(() => {
        setRawData({data: {}, interval: 0})
        setBackTimeRange({point: Date.now(), length: 100})
    }, [pool, interval, type]);

    useEffect(() => {
        if (backTimeRange.length < 1) return
        const back = query(
            ref(getDatabase(), `charts/${pool}/${type}/${getAvailable(interval)}`),
            orderByKey(),
            endBefore(`${backTimeRange.point}`),
            limitToLast(backTimeRange.length * interval / getAvailable(interval))
        )
        return onValue(back, snapshot => {
            const vals = snapshot.val()
            setRawData(currData => {
                if (currData.interval === getAvailable(interval)) {
                    return {data: {...currData.data, ...vals}, interval: getAvailable(interval)};
                } else {
                    return {data: {...vals}, interval: getAvailable(interval)};
                }
            });
        })
    }, [backTimeRange, type, interval]);

    useEffect(() => {
        if (fwdTimeRange.length < 1) return
        const fwd = query(
            ref(getDatabase(), `charts/${pool}/${type}/${getAvailable(interval)}`),
            orderByKey(),
            startAfter(`${fwdTimeRange.point}`),
            limitToFirst(fwdTimeRange.length * interval / getAvailable(interval))
        )
        return onValue(fwd, snapshot => {
            const vals = snapshot.val()
            setRawData(currData => {
                if (currData.interval === getAvailable(interval)) {
                    return {data: {...currData.data, ...vals}, interval: getAvailable(interval)};
                } else {
                    return {data: {...vals}, interval: getAvailable(interval)};
                }
            });
        })
    }, [fwdTimeRange, type, interval]);

    useEffect(() => {
        const buckets = DefaultDict(() => [])
        Object.entries(rawData.data).forEach(([key, value]) => buckets[roundDown(key, interval)].push([key, value]))

        const offset = new Date().getTimezoneOffset()
        let data = Object.entries(buckets)
            .map(([timestr, prices]) => ({
                time: roundDown(timestr, interval) / 1000 - offset * 60,
                ...prices
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .reduce((acc, [k, v]) => mergeTimeData(acc, v), null)
            }))
            .sort((a, b) => a.time - b.time)
        let lastClose = data[0]?.open;
        for (let datum of data) {
            datum.open = lastClose
            lastClose = datum.close
        }
        if (stable_coin) {
            data = data.map(({time, high, low, open, close}) => ({
                time,
                low: 1 / high,
                high: 1 / low,
                open: 1 / open,
                close: 1 / close
            }))
        }
        setBarsData(data)
    }, [rawData, interval]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setHeight(entry.contentRect.height)
                setWidth(entry.contentRect.width)
            }
        })
        chartContainer.current && resizeObserver.observe(chartContainer.current, {box: "content-box"})
        return () => resizeObserver.disconnect()
    }, [chartContainer]);

    const handleVisibleLogicalRangeChange = useCallback(() => {
        if (!timeScale.current || !candleSeries.current) {
            return;
        }
        if (timer.current !== null) {
            return;
        }
        timer.current = window.setTimeout(() => {
            if (!timeScale.current || !candleSeries.current) {
                return;
            }

            const logicalRange = timeScale.current.getVisibleLogicalRange();
            if (logicalRange !== null) {
                const barsInfo = candleSeries.current.barsInLogicalRange(logicalRange);
                if (barsInfo !== null && (barsInfo.barsBefore < 10 || barsInfo.barsAfter < 10)) {
                    setBackTimeRange({
                        point: barsInfo.from * 1000,
                        length: Math.ceil(-barsInfo.barsBefore * 2 * interval / getAvailable(interval))
                    })
                    setFwdTimeRange({
                        point: barsInfo.to * 1000,
                        length: Math.ceil(-barsInfo.barsAfter * 2 * interval / getAvailable(interval))
                    })
                }
            }
            timer.current = null;
        }, 500);
    }, []);

    return (
        <div ref={chartContainer} {...props}>
            <Chart
                width={width}
                height={height}
                layout={{
                    background: {color: "#161b26"},
                    textColor: "#C3BCDB",
                    fontSize: 14
                }}
                grid={{
                    vertLines: {color: "#232631"},
                    horzLines: {color: "#232631"},
                }}
                leftPriceScaleOptions={{
                    borderColor: "#71649C"
                }}
                localization={{
                    priceFormatter: formatPrice
                }}
            >
                <CandlestickSeries
                    ref={candleSeries}
                    data={barsData}
                    reactive={true}
                    priceFormat={{
                        format: a => a.toString(),
                        minMove: 1e-12,
                    }}
                    borderColor={"#71649C"}
                />
                <TimeScale
                    ref={timeScale}
                    timeVisible={true}
                    shiftVisibleRangeOnNewBar={fwdTimeRange.point === roundDown(Date.now(), interval)}
                    onVisibleLogicalRangeChange={handleVisibleLogicalRangeChange}
                />
            </Chart>
        </div>
    )
}