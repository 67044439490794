const formatDistanceLocale = {
    lessThanXSeconds: {
        one: "<1s",
        other: "<{{count}}s",
    },
    xSeconds: {
        one: "1s",
        other: "{{count}}s",
    },
    halfAMinute: "1/2m",
    lessThanXMinutes: {
        one: "<1m",
        other: "<{{count}}m",
    },
    xMinutes: {
        one: "1m",
        other: "{{count}}m",
    },
    aboutXHours: {
        one: "~1h",
        other: "~{{count}}h",
    },
    xHours: {
        one: "1h",
        other: "{{count}}h",
    },
    xDays: {
        one: "1D",
        other: "{{count}}D",
    },
    aboutXWeeks: {
        one: "~1W",
        other: "~{{count}}W",
    },
    xWeeks: {
        one: "1W",
        other: "{{count}}W",
    },
    aboutXMonths: {
        one: "~1M",
        other: "~{{count}}M",
    },
    xMonths: {
        one: "1M",
        other: "{{count}}M",
    },
    aboutXYears: {
        one: "~1Y",
        other: "~{{count}}Y",
    },
    xYears: {
        one: "1Y",
        other: "{{count}}Y",
    },
    overXYears: {
        one: ">1Y",
        other: ">{{count}}Y",
    },
    almostXYears: {
        one: "~1Y",
        other: "~{{count}}Y",
    },
};
export const formatDistance = (token, count, options) => {
    let result;
    const tokenValue = formatDistanceLocale[token];
    if (typeof tokenValue === "string") {
        result = tokenValue;
    }
    else if (count === 1) {
        result = tokenValue.one;
    }
    else {
        result = tokenValue.other.replace("{{count}}", count.toString());
    }
    if (options?.addSuffix) {
        if (options.comparison && options.comparison > 0) {
            return "in " + result;
        }
        else {
            return result + " ago";
        }
    }
    return result;
};
