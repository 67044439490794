import {TableVirtuoso} from "react-virtuoso";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {
    collection,
    count,
    doc,
    getAggregateFromServer,
    limit,
    onSnapshot,
    orderBy,
    query,
    where
} from "firebase/firestore";
import {db} from "../firebase/firebase-config.js";
import {Page, SpacerXSmall, TextTitle, UnstyledA, UnstyledLink} from "../globalStyles.js";
import {Header} from "../components/header/Header.jsx";
import {Amount} from "../components/Amount.jsx";
import {useUserData} from "../firebase/AuthProvider.jsx";
import {useInterval} from "../hooks/useInterval.js";
import {LoadingIcon} from "../components/transfer/common.jsx";
import link from "../assets/external-link.svg";
import {useTitle} from "../hooks/useTitle.js";


const TableWrapper = styled.div`
    flex-grow: 1;
    padding-top: 8px;
    display: flex;
`

const PageMain = styled.div`
    border-width: 1px;
    border-style: solid;
    border-color: #262730;
    border-radius: 8px;
    background-color: #1c1c25;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`

const StyledTableVirtuoso = styled(TableVirtuoso)`
    width: 100%;
    height: unset !important;

    & table {
        width: 100%;
    }

    & thead {
        z-index: unset !important;
    }
`

const StyledTd = styled.td`
    color: ${({c}) => c || ""};
    padding: 4px;
    font-size: 20px;
`

const Tr = styled.tr`
    background-color: ${({bg}) => bg ?? "#2f2f3e"};
`

const Th = styled.th`
    background-color: #1b1c25;
    padding: 6px;
    width: ${({w}) => w || ""};

    &:hover {
        cursor: pointer;
    }
`

const StyledLink = styled(UnstyledA)`
    display: flex;
    align-items: center;
    align-content: center;
    width: fit-content;
    margin: auto;
`

const leaderboardRef = collection(db, "leaderboard");

/**
 *
 * @param records {LeaderboardRecord[]}
 * @param myRecord {LeaderboardRecord}
 * @param myRank {number}
 * @return {(LeaderboardRecord & {rank: number, my?: true})[]}
 */
function makeLeaderboardOrdering(records, myRecord, myRank) {
    const rankedRecords = records.map((r, i) => ({...r, rank: i + 1}))
    if (!myRecord) return rankedRecords;
    const myWalletIndex = records.map(r => r.wallet).indexOf(myRecord.wallet)
    let myRankedRecord
    if (myWalletIndex < 0) {
        myRankedRecord = {...myRecord, rank: myRank, my: true}
    } else {
        myRankedRecord = {...myRecord, rank: rankedRecords[myWalletIndex].rank, my: true}
        rankedRecords.splice(myWalletIndex, 1)
    }
    return [myRankedRecord, ...rankedRecords]
}

export function Leaderboard(props) {
    const userData = useUserData()
    const [records, setRecords] = useState([])
    const [myRecord, setMyRecord] = useState(null)
    const [myRank, setMyRank] = useState(-1)
    const [loadLimit, setLoadLimit] = useState(50)

    useTitle("Leaderboard")

    useEffect(() => userData?.leaderboard_id && onSnapshot(
        doc(leaderboardRef, userData?.leaderboard_id),
        snap => {
            setMyRecord(snap.data())
        }), [userData])

    useInterval(() => {
            myRecord && getAggregateFromServer(
                query(leaderboardRef,
                    orderBy("total_score", "desc"),
                    where("total_score", ">", myRecord?.data()?.total_score)
                ),
                {count: count()})
                .then(result => setMyRank(result.data().count));
        }
        , 30 * 1000, [myRecord])

    useEffect(() => onSnapshot(
        query(leaderboardRef,
            orderBy("total_score", "desc"),
            limit(loadLimit)
        ),
        snap => {
            setRecords(snap.docs.map(doc => doc.data()))
        }), [loadLimit])

    return <Page>
        <Header/>
        <SpacerXSmall/>
        <PageMain>
            <TextTitle>Leaderboard</TextTitle>
            <TableWrapper>
                <StyledTableVirtuoso
                    data={makeLeaderboardOrdering(records, myRecord, myRank)}
                    fixedHeaderContent={() => <tr>
                        <Th w={"25vw"}>RANK</Th>
                        <Th w={"25vw"}>WALLET</Th>
                        <Th w={"25vw"}>POINTS</Th>
                    </tr>}
                    itemContent={(i, record) => {
                        const c = record.my && "#6fd3cb"
                        return <>
                            <StyledTd c={c}>{record.rank}</StyledTd>
                            <StyledTd c={c}>
                                <StyledLink target={"_blank"} href={`https://seitrace.com/address/${record.wallet}`}>
                                    {record.wallet.slice(0, 5)}
                                    ...
                                    {record.wallet.slice(42 - 4)}
                                    <LoadingIcon src={link}/>
                                </StyledLink>
                            </StyledTd>
                            <StyledTd c={c}><Amount num={record.total_score}/></StyledTd>
                        </>;
                    }}
                    endReached={() => records.length === loadLimit && setLoadLimit(l => l + 50)}
                    increaseViewportBy={400}
                    {...props}
                />
            </TableWrapper>
        </PageMain>
    </Page>
}