import styled from "styled-components";
import {TextSubText} from "../../globalStyles.js";
import {useEffect} from "react";

export const Container = styled.div`
    position: relative;
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    width: 100%;
`
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 16px;
`
export const Text = styled(TextSubText)`
    margin: 0;
    width: 100%;
    text-align: right;
`
export const Icon = styled.img`
    height: 1.5em;
`
export const IconBox = styled.div`
    width: 1.5em;
    display: flex;
    justify-content: center;
    margin-right: 10px;
`
export const Dialogue = styled.div`
    display: ${({visible}) => (visible ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    background: #1b1c25;
    border-radius: 16px;
    right: 0;
    padding: 8px 16px;
    margin: 8px;
    font-size: 16px;
    z-index: ${({z}) => z || 2 };
    border: 2px solid #262730FF;
    width: max-content;
`
export const DialogueRow = styled(Row)`
    margin: 8px;
    justify-content: flex-start;

    &:hover {
        cursor: pointer;
    }
`

export function useOnClickOutside(ref, effect) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                effect()
            }
        }

        // Bind the event listener
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [ref, effect]);
}