import {SpacerLarge, SpacerMedium, SpacerXLarge, TextSubTitle, TextTitle} from "../globalStyles.js";
import styled from "styled-components";
import {useUserData} from "../firebase/AuthProvider.jsx";
import {useState} from "react";
import {HistoryTable} from "../components/transfer/HistoryTable.jsx";
import {Deposit} from "../components/transfer/Deposit.jsx";
import {Withdraw} from "../components/transfer/Withdraw.jsx";
import {useTitle} from "../hooks/useTitle.js";

const CenteringDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(75vw + 64px);
    margin: 0 auto -1px;
    background: conic-gradient(from 315deg at 50% 50%, #00000000 50%, #00000000 50%, #1b1c25 50%, #1b1c25 50%, #1b1c25 75%, #1b1c25 75%, #00000000 75%, #00000000 75%);
    z-index: 1;
`

const Tab = styled.div`
    width: 100%;
    background: ${({selected}) => selected ? "#1b1c25" : "#18181b"};
    padding: 16px;
    flex-basis: 0;
    flex-grow: 1;
    border-collapse: collapse;
    margin: 0;
    cursor: pointer;
`

const LeftTabSelected = styled(Tab)`
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-top: solid 1px #262730;
    border-left: solid 1px #262730;
    border-right: solid 1px transparent;
    background-image: linear-gradient(#1b1c25, #1b1c25), linear-gradient(180deg, #262730 50%, #1b1c25 51%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    margin-left: -1px;
`

const LeftTabUnselected = styled(Tab)`
    border-bottom-right-radius: 32px;
    border-right: solid 1px transparent;
    border-bottom: solid 1px transparent;
    background-color: transparent;
    background-image: linear-gradient(#18181b, #18181b), conic-gradient(from -90deg at 50% 50%, #00000000 50%, #00000000 50%, #262730FF 50%, #262730FF 50%, #262730FF 75%, #262730FF 75%, #00000000 75%, #00000000 75%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    margin-right: -1px;
`
const LeftTab = props => props.selected ? <LeftTabSelected {...props}/> : <LeftTabUnselected {...props}/>

const RightTabSelected = styled(Tab)`
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-top: solid 1px #262730;
    border-right: solid 1px #262730;
    border-left: solid 1px transparent;
    background-image: linear-gradient(#1b1c25, #1b1c25), linear-gradient(180deg, #262730 50%, #1b1c25 51%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    margin-right: -1px;
`
const RightTabUnselected = styled(Tab)`
    border-bottom-left-radius: 32px;
    border-left: solid 1px transparent;
    border-bottom: solid 1px transparent;
    background-color: transparent;
    background-image: linear-gradient(#18181b, #18181b), conic-gradient(from 0deg at 50% 50%, #00000000 50%, #00000000 50%, #262730FF 50%, #262730FF 50%, #262730FF 75%, #262730FF 75%, #00000000 75%, #00000000 75%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    margin-left: -1px;
`
const RightTab = props => props.selected ? <RightTabSelected {...props}/> : <RightTabUnselected {...props}/>

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75vw;
    margin: 0 auto;
    background: #1b1c25;
    border-radius: ${({position}) => position === "left" ? "0 32px" : "32px 0"} 32px 32px;
    padding: 32px;
    border: 1px solid #262730FF;
`

export function Transfer() {
    const userData = useUserData()
    const [position, setPosition] = useState("left")

    useTitle("Transfer")

    return userData && <CenteringDiv>
        <SpacerXLarge/>
        <TextTitle>Transfer Funds</TextTitle>
        <SpacerLarge/>
        <TabContainer>
            <LeftTab selected={position === "left"} onClick={() => setPosition("left")}>Deposit</LeftTab>
            <RightTab selected={position === "right"} onClick={() => setPosition("right")}>Withdraw</RightTab>
        </TabContainer>
        <MessageContainer position={position}>
            <SpacerMedium/>
            {position === "left" ?
                <Deposit seiWallet={userData.wallets.sei}/> :
                <Withdraw seiWallet={userData.wallets.sei}/>
            }
            <SpacerXLarge/>
            <TextSubTitle>History</TextSubTitle>
            <HistoryTable/>
        </MessageContainer>
    </CenteringDiv>
}