import {useEffect, useState} from "react";
import {delay} from "../utils.js";

async function run(f) {
    return await f()
}

/**
 * @template A
 * @param {(function(): Promise<null|A>)|A} fn
 * @param {number} interval
 * @param {*[]} deps
 * @param placeholder {undefined|null|A}
 * @return A | null
 */
export function useInterval(fn, interval, deps, placeholder = undefined) {
    const [state, setState] = useState(null)
    useEffect(() => {
        let active = true
        placeholder !== undefined && setState(placeholder)
        run(fn)
            .catch(err => console.log(err) || null)
            .then(result => active && setState(result))
        const intervalId = setInterval(() => {
            placeholder !== undefined && setState(placeholder)
            run(fn)
                .catch(err => console.log(err) || null)
                .then(result => active && setState(result))
        }, interval)
        return () => {
            clearInterval(intervalId);
            active = false
        }
    }, deps)
    return state
}


/**
 * @template A
 * @param {(function(): Promise<null|A>)|A} fn
 * @param {number} interval
 * @param {*[]} deps
 * @param placeholder {undefined|null|A}
 * @param initial
 * @return A | null
 */
export function useSingleInterval(fn, interval, deps, placeholder = undefined, initial = null) {
    const [state, setState] = useState(initial)
    useEffect(() => {
        placeholder !== undefined && setState(placeholder)
        let running = true

        async function loop() {
            while (running) {
                await Promise.all([
                    run(fn)
                        .catch(err => console.log(err) || null)
                        .then(setState),
                    delay(interval)
                ])
            }
        }

        loop().then()
        return () => (running = false) || undefined
    }, deps)
    return state
}