import React, {useEffect, useState} from "react";
import {useSeiToSolQuote} from "../../hooks/useQuotes.js";
import {
    BuySellColors,
    SpacerLarge,
    SpacerMedium,
    TextSubText,
    TextSubTitle,
    TransactionButton
} from "../../globalStyles.js";
import {Arrow, Col, LoadingIcon, Row, StyledTextField} from "./common.jsx";
import {StyledCopyableText} from "../FancyText.jsx";
import arrow_right from "../../assets/arrow-right.svg";
import black_spinner from "../../assets/black-spinner.svg";
import white_spinner from "../../assets/tube-spinner.svg";
import TransferConfirmDialog from "./TransferConfirmDialog.jsx";
import {Amount} from "../Amount.jsx";
import {useCryptoConnection} from "../../firebase/CryptoConnectionProvider.jsx";
import useSeiBalance from "../../hooks/useSeiBalance.js";
import {useOngoingTransaction} from "../../hooks/useUserTransaction.js";

const txTypes = [
    "SolanaDepositTransaction",
    "SolanaWithdrawTransaction",
    "SeiDepositTransaction",
    "SeiWithdrawTransaction"
]

export function Withdraw({seiWallet}) {
    const connection = useCryptoConnection()
    const [seiAmount, setSeiAmount] = useState("")
    const withdrawQuoteCont = useSeiToSolQuote(
        connection.protocol === "solana" && seiAmount && parseFloat(seiAmount),
        100
    )
    const [withdrawQuote, setWithdrawQuote] = useState(null)
    const [enabled, setEnabled] = useState(true)
    const transaction = useOngoingTransaction(txTypes)
    const [message, setMessage] = useState(null)
    const token = {solana: "SOL USDC", sei: "SEI"}[connection.protocol]
    const seiBalance = useSeiBalance(seiWallet)
    const isTransactionDisabled = isNaN(parseFloat(seiAmount)) || parseFloat(seiAmount) > seiBalance || connection.protocol !== "sei" && (!enabled || !withdrawQuote || withdrawQuote.waiting)

    useEffect(() => {
        !message && enabled && setWithdrawQuote(withdrawQuoteCont)
    }, [message, withdrawQuoteCont, enabled]);

    useEffect(() => {
        setEnabled(!transaction || transaction.status !== "in-progress")
    }, [transaction]);

    const handleWithdraw = async () => {
            try {
                console.log("starting withdraw")
                setEnabled(false)
                let tid = await connection.withdrawToWallet(parseFloat(seiAmount), withdrawQuote);
                if (!tid) {
                    setEnabled(true)
                }
            } catch (e) {
                console.log(e)
                setEnabled(true)
            }
        };

    const showDialog = () => !isTransactionDisabled && setMessage({
        solana: <>
            Your {seiAmount} SEI will be transferred as approximately <Amount num={withdrawQuote?.quote}/> SOL USDC.
        </>,
        sei: <>
            Your {seiAmount} SEI will be transferred to your wallet.
        </>,
    }[connection.protocol])

    return <>
        <TransferConfirmDialog
            message={message}
            subMessage={`Target wallet: ${connection.address}`}
            showWarning={connection.protocol === "solana"}
            onCancel={() => setMessage(null)}
            onConfirm={() => {
                handleWithdraw().then()
                setMessage(null)
            }}
        />
        <TextSubTitle>Withdraw {token} from your SEI wallet</TextSubTitle>
        <SpacerMedium/>
        <Row w={"100%"}>
            <Col fb={0} fg={1}>
                <Col ml={"auto"}>
                    <TextSubText>From</TextSubText>
                    <StyledCopyableText length={14} text={seiWallet}/>
                </Col>
            </Col>
            <Col>
                <TextSubText style={{visibility: "hidden"}}>a</TextSubText>
                <Arrow src={arrow_right} alt="arrow_right"/>
            </Col>
            <Col>
                <TextSubText>Amount</TextSubText>
                <StyledTextField label={"sei amount"} value={seiAmount} setValue={setSeiAmount}/>
            </Col>
            <Col>
                <TextSubText style={{visibility: "hidden"}}>a</TextSubText>
                <Arrow src={arrow_right} alt="arrow_right"/>
            </Col>
            <Col fb={0} fg={1}>
                <Col mr={"auto"}>
                    <TextSubText>To</TextSubText>
                    <StyledCopyableText length={12} text={connection.address}/>
                </Col>
            </Col>
        </Row>
        <SpacerLarge/>
        <TransactionButton
            {...BuySellColors.sell}
            disabled={isTransactionDisabled}
            onClick={showDialog}
        >
            Withdraw
            {!enabled && <LoadingIcon src={black_spinner} alt="loading"/>}
        </TransactionButton>
        <SpacerMedium/>
        {connection.protocol === "solana" &&
            <TextSubTitle h={"32px"}>{(withdrawQuote?.waiting &&
                    <LoadingIcon s={"1.5em"} src={white_spinner} alt="loading"/>)
                || (withdrawQuote?.quote && <><Amount num={withdrawQuote?.quote}/> USDC</>)
                || "..."
            }</TextSubTitle>}
    </>;
}