import styled from "styled-components";
import {roundDown} from "../utils.js";

const Subscript = styled.span`
    font-size: 0.75em;
    position: relative;
    top: 0.2em;
`

export const Amount = ({num, precision = 3, integer = false}) => {
    if (isNaN(num)) {
        return num;
    } else {
        num = parseFloat(num)
        const lookup = ["", "k", "M", "G", "T", "P", "E"];
        const digits = roundDown(Math.max(Math.log10(Math.abs(num)), 0), 3);
        if (integer && digits / 3 === 0) return num.toString()
        const numStr = new Intl.NumberFormat('en-IN', {
            minimumSignificantDigits: precision,
            maximumSignificantDigits: precision,
            style: "decimal",
            roundingMode: "floor"
        }).format(num / 10 ** digits)
        const found = numStr.match(/^0\.(000+)(\d*?)$/)
        if (found) {
            return <>0.0<Subscript>{found[1].length}</Subscript>{found[2]}{lookup[digits / 3]}</>
        }
        return <>{numStr}{lookup[digits / 3]}</>;
    }
}