import React from 'react';
import styled from 'styled-components';

// Styled components
const BarContainer = styled.div`
    display: flex;
    padding: ${({p}) => p || "4px"};
`;

export const SelectionOption = styled.div`
    padding: 5px;
    cursor: pointer;
    color: ${({isSelected, text = "#fff", selected = 'hsl(225,80%,65%)'}) => (isSelected ? selected : text)};
    transition: color 0.2s ease;
    
    &:hover {
        border-radius: 8px;
        background-color: #464853;
        color: ${({selected = 'hsl(225,80%,65%)'})=>selected};
    }
    
    &:not(:last-child) {
        margin-right: 10px;
    }
`;

// Bar Component
export const SelectionBar = ({value, setValue, children, ...props}) => {
    const handleOptionClick = (option) => {
        setValue(option);
    };

    // Clone the children to add the onClick and isSelected props
    const optionsWithProps = React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
            isSelected: child.props.value === value,
            onClick: () => handleOptionClick(child.props.value),
        });
    });

    return (
        <BarContainer {...props}>
            {optionsWithProps}
        </BarContainer>
    );
};