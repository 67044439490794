import {
    Bold,
    Page,
    SpacerGrow,
    SpacerMedium,
    SpacerXLarge,
    StyledButton,
    TextSubText,
    TextSubTitle,
    TextTitle
} from "../globalStyles.js";
import styled from "styled-components";
import {useFirebaseAuth, useUserData} from "../firebase/AuthProvider.jsx";
import {useState} from "react";
import {Progress} from "../components/Progress.jsx";
import {afterFirstLogin} from "../firebase/remoteFunctions.js";
import {useLocation} from "wouter";
import PrivateKeyFinalChanceDialog from "../components/PrivateKeyFinalChanceDialog.jsx";
import {useTitle} from "../hooks/useTitle.js";

const CenteringDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 75vw;
    width: fit-content;
    margin: 0 auto;
    background: #1b1c25;
    border-radius: 32px;
    padding: 32px;
    border: 1px solid #262730FF;
`

const ConnectRow = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const VerticalLine = styled.div`
    border-left: 1px solid #fff;
    width: 1px;
`

const headings = [
    "Generate your trading wallet",
    "This is your trading wallet",
    "Welcome to SEI"
]

export function Onboarding() {
    const [, navigate] = useLocation();
    const firstLoginData = useUserData()
    const user = useFirebaseAuth()
    const [protocol, address] = user?.uid?.split("_") || []
    const [page, setPage] = useState(0)
    let [isOpen, setIsOpen] = useState(false)

    useTitle("Welcome to Slippage")

    const handleNext = () => afterFirstLogin({})
        .then(() => {
            firstLoginData.firstLogin = false
            navigate("/transfer");
        })

    return firstLoginData?.firstLogin && user && (
        <Page>
            <PrivateKeyFinalChanceDialog
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                onConfirm={() => {
                    setIsOpen(false)
                    setPage(2)
                }}
            />
            <SpacerMedium/>
            <Progress style={{margin: "0 auto"}} total={3} current={page}/>
            <CenteringDiv>
                <SpacerXLarge/>
                <TextTitle>{headings[page]}</TextTitle>
                <SpacerGrow fg={1}/>
                {[
                    <MessageContainer>
                        <TextSubTitle>you are logged in with {protocol} address</TextSubTitle>
                        <TextSubTitle w={"bold"}>{address}</TextSubTitle>
                        <SpacerXLarge/>
                        <StyledButton onClick={() => setPage(1)}>Generate</StyledButton>
                    </MessageContainer>,
                    <MessageContainer>
                        <TextSubTitle>your sei wallet</TextSubTitle>
                        <TextSubTitle w={"bold"}>{firstLoginData.wallets.sei}</TextSubTitle>
                        <SpacerMedium/>
                        <TextSubTitle>your private key</TextSubTitle>
                        <TextSubTitle w={"bold"}>{firstLoginData.private_keys.sei_pkey}</TextSubTitle>
                        <TextSubText ta={"center"}>
                            Copy your private key and store it in a safe location. Your private key will NOT be
                            displayed again
                        </TextSubText>
                        <SpacerXLarge/>
                        <StyledButton onClick={() => setIsOpen(true)}>Continue</StyledButton>
                    </MessageContainer>,
                    <MessageContainer>
                        <TextSubTitle>you have <Bold>0 SEI</Bold>, transfer SEI now</TextSubTitle>
                        <TextSubTitle w={"bold"}>{firstLoginData.wallets.sei}</TextSubTitle>
                        <SpacerXLarge/>
                        <StyledButton onClick={handleNext}>Transfer</StyledButton>
                    </MessageContainer>
                ][page]}
                <SpacerGrow fg={3}/>
            </CenteringDiv>
        </Page>
    )
}