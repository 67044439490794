import './App.css'

import {Route, Switch} from "wouter";
import {Header} from "../components/header/Header.jsx";
import {FirebaseAuthProvider} from "../firebase/AuthProvider.jsx";
import {Onboarding} from "./Onboarding.jsx";
import styled from "styled-components";

import '@solana/wallet-adapter-react-ui/styles.css';
import {Transfer} from "./Transfer.jsx";
import {TokenPage} from "./TokenPage.jsx";
import {Main} from "./Main.jsx";
import {CryptoConnectionProvider} from "../firebase/CryptoConnectionProvider.jsx";
import {Leaderboard} from "./Leaderboard.jsx";
import {Profile} from "./Profile.jsx";
import {Referral} from "./Referral.jsx";
import {CurrentSeiPriceProvider} from "../firebase/CurrentSeiPriceProvider.jsx";
import {Mobile} from "./Mobile.jsx";

const Desktop = styled.div`
    width: calc(100vw - 16px);
    min-height: calc(100vh - 16px);
    @media (max-width: 767px) {
        display: none;
    }
`

function App() {
    return <CryptoConnectionProvider>
        <FirebaseAuthProvider>
            <CurrentSeiPriceProvider>
                <Mobile/>
                <Desktop>
                    <Switch>
                        <Route path="/">
                            <Main/>
                        </Route>
                        <Route path="/onboarding" component={Onboarding}/>
                        <Route path="/transfer">
                            <Header/>
                            <Transfer/>
                        </Route>
                        <Route path="/leaderboard">
                            <Leaderboard/>
                        </Route>
                        <Route path="/profile">
                            <Profile/>
                        </Route>
                        <Route path="/token/:pool">
                            {({pool}) => <TokenPage poolAddress={pool}/>}
                        </Route>
                        <Route path="/referral/:referral_code">
                            {({referral_code}) => <Referral referral_code={referral_code}/>}
                        </Route>
                        <Route>404: No such page!</Route>
                    </Switch>
                </Desktop>
            </CurrentSeiPriceProvider>
        </FirebaseAuthProvider>
    </CryptoConnectionProvider>
}

export default App
